const DEV_ROOT = "http://localhost:3002/";
const PROD_ROOT = "https://polar-reef-74128-7c129d9dd252.herokuapp.com/";
const ROOT = process.env.NODE_ENV == "production" ? PROD_ROOT : DEV_ROOT;
import { getAccessToken } from "@privy-io/react-auth";

// NO SECURITY
// Anyone Can call these
// NO SECURITY

export async function checkGeolocation() {
  return fetch(`${ROOT}geolocation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json" // Specify the content type
    }
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.error(error));
}

export const fetchAssets = async () => {
  try {
    const response = await fetch(`${ROOT}assets`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const assets = await response.json();
    return assets; // Return the data if needed
  } catch (error) {
    console.error("Error fetching assets:", error);
  }
};

export async function joinWaitlist(email: string, selectedCompany: string) {
  return fetch(`${ROOT}join-waitlist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json" // Specify the content type
    },
    body: JSON.stringify({ email, selectedCompany })
  })
    .then((response) => {
      if (!response.ok) {
        // If the response is not ok (e.g., status is 500), throw an error
        return response.json().then((json) => {
          throw new Error(json.message || "Server error");
        });
      }
      console.log("Test", response);
      return response.json(); // Otherwise, parse and return the JSON
    })
    .catch((error) => {
      console.error("Fetch error:", error);
      throw error; // Re-throw the error to be caught by the caller
    });
}

// THESE FUNCTIONS SHOULD ONLY WORK FOR CALLER
// IE ONE USER SHOULD NOT BE ABLE TO GET ANOTHER USER DATA
// THESE FUNCTIONS SHOULD ONLY WORK FOR CALLER

export async function writeUserAttestation(
  user: any,
  answers: object,
  name: string,
  country: string,
  age: string,
  gender: string
) {
  console.log(`Requesting: ${ROOT}user/${user.id} `);
  console.log("Answers?", answers);
  console.log("User", user);

  const privyToken = await getAccessToken();

  return fetch(`${ROOT}users/${user.id}/attestation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${privyToken}`
    },
    body: JSON.stringify({ ...user, answers, name, country, age, gender }) // Convert the data to a JSON string
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((error) => console.error(error));
}

// this function gets prinx user from privy user object
// the reason for this is because we cannot modify the privy user object
// and we store other data, such as NFT id when whitelisted, attestation answers
// historical data, as well as holdings (basically a backup of blockchain)

export async function getPrinxUser(email: string) {
  if (!email) {
    console.warn("Attempting To Get Prinx User With Undefined Param -- Abandoning");
    return;
  }
  const privyToken = await getAccessToken();

  return fetch(`${ROOT}users/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      Authorization: `Bearer ${privyToken}` // ✅ Send Privy JWT
    }
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.error(error));
}

export async function getPortfolioData(smart_wallet: string) {
  if (!smart_wallet) {
    console.warn("Attempting To Get Prinx User With Undefined Param -- Abandoning");
    return;
  }
  const privyToken = await getAccessToken();

  return fetch(`${ROOT}portfolio/${smart_wallet}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      Authorization: `Bearer ${privyToken}` // ✅ Send Privy JWT
    }
  })
    .then((response) => response.json())
    .then((json) => {
      const transformedHoldings = json.holdings.map((holding: any) => {
        // Calculate the sum of the `value` field in the items array
        const totalValue = holding.items.reduce((sum: number, item: any) => sum + item.value, 0);

        // Return a new object with `date` and the `value` sum
        return {
          date: new Date(holding.date), // Keep the original date
          price: totalValue // Summed value
        };
      });
      return transformedHoldings;
    })
    .catch((error) => console.error(error));
}
//record transaction after purchase has been made
// TODO Type Out data object here, don't remember what that is
export async function recordTransaction(data: object): Promise<void> {
  const privyToken = await getAccessToken();
  console.log("Data Call", data);
  return fetch(`${ROOT}transactions/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      Authorization: `Bearer ${privyToken}` // ✅ Send Privy JWT
    },
    body: JSON.stringify(data) // Convert the data to a JSON string
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((error) => console.error(error));
}

// ADMIN PRIVLEDGES REQUIRED
// send with bearer, obfuscated token
// ADMIN PRIVLEDGES REQUIRED

//
const bearer = process.env.REACT_APP_PRINX_ADMIN_SECRET;

export async function burnNFT(tokenId: number, auth: any) {
  console.log("TokenId to Burn", tokenId);
  return fetch(`${ROOT}admin/burn`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearer}` // ✅ Send token in header
    },
    body: JSON.stringify({ tokenId, auth }) // Convert the data to a JSON string
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((error) => console.error(error));
}

export async function getUsersWithCoin(coin: string) {
  console.log(`Requesting: ${ROOT}admin/coins/${coin} `);
  console.log("Token?", bearer);
  console.log("Pricesss?", process.env);
  return fetch(`${ROOT}admin/coins/${coin}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      Authorization: `Bearer ${bearer}` // ✅ Send token in header
    }
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((error) => console.error(error));
}

export async function generateCDPToken(walletAddress: string, prinxUserId: string) {
  const requestBody = {
    sw: walletAddress,
    prinxUserId
  };

  try {
    const response = await fetch(`${ROOT}coinbase-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
        // Replace with your actual Coinbase API key or authorization token
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null;
  }
}
export async function offrampCoins(prinxUserId: string) {
  console.log("Offramping Coins");
  try {
    const response = await fetch(`${ROOT}coinbase-sell-transaction`, {
      method: "POST",
      body: JSON.stringify({ prinxUserId: prinxUserId }),
      headers: {
        "Content-Type": "application/json"
        // Replace with your actual Coinbase API key or authorization token
      }
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null;
  }
}
