import React from "react";

const LoadingTableSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse w-full">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex w-full items-center border-t py-2">
          {/* Firm Column */}
          <div className="w-[35%] flex items-center">
            <div className="p-1 mr-2 flex-shrink-0">
              <div className="w-8 h-8 min-w-8 min-h-8 bg-gray-300 rounded-full"></div>
            </div>
            <div className="flex flex-col w-full">
              <div className="h-3 w-2/3 bg-gray-300 rounded mb-1"></div>
              <div className="h-3 w-1/2 bg-gray-200 rounded"></div>
            </div>
          </div>

          {/* Share Price Column */}
          <div className="w-[20%] px-1">
            <div className="h-3 w-3/4 bg-gray-300 rounded"></div>
          </div>

          {/* Quantity Held Column */}
          <div className="w-[20%]">
            <div className="h-3 w-3/4 bg-gray-300 rounded"></div>
          </div>

          {/* Estimated Value Column */}
          <div className="w-[25%]">
            <div className="h-3 w-3/4 bg-gray-300 rounded"></div>
          </div>

          {/* Trade Button Column */}
          <div className="w-[25%] flex justify-end">
            <div className="h-6 w-1/2 bg-gray-300 rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadingTableSkeleton;
