import React, { FC, useContext, useState } from "react";
import { formatToDollar } from "common";
import { useFundWallet } from "@privy-io/react-auth";
import FundingModal from "components/Modals/FundingModal";
import BlockChainContext from "blockchainContext";
import LoadingOneLineSkeleton from "components/common/Loaders/LoadingOneLineSkeleton";

interface BalanceSummaryProps {
  usdcBalance: string;
}

const BalanceSummary: FC<BalanceSummaryProps> = ({ usdcBalance }) => {
  const { fundWallet } = useFundWallet();
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const { smartWalletAddress, ethersProvider, usdcBalanceLoaded } = useContext(BlockChainContext);

  return (
    <div className="bg-white p-4 flex-col h-full w-full">
      <div className="text-m">Current Balance</div>
      <div className="text-2xl mt-4">
        {usdcBalanceLoaded ? formatToDollar(usdcBalance) : <LoadingOneLineSkeleton height="h-8" />}
      </div>
      
      <div className="mt-6 flex flex-col space-y-4">
        <button
          className="w-full py-2 pr-6 rounded bg-blue-500 text-white border border-blue-500  hover:ring-2 hover:ring-blue-500"
          onClick={() => setDepositModalOpen(true)}
        >
          Deposit
        </button>
        <button
          className="w-full py-2 pr-6 rounded border border-blue-500 text-blue-500 bg-transparent hover:ring-2 hover:ring-blue-500"
          onClick={() => setWithdrawModalOpen(true)}
        >
          Withdraw
        </button>
      </div>

      <div className="text-gray-400 mt-8">
        Your balance allows you to invest in offerings and receive distributions. This is not factored
        into your portfolio value.
      </div>

      {depositModalOpen && (
        <FundingModal
          setFundingClicked={setDepositModalOpen}
          sw={smartWalletAddress}
          ethersProvider={ethersProvider}
          usdcBalance={usdcBalance}
          type="Deposit"
        />
      )}

      {withdrawModalOpen && (
        <FundingModal
          setFundingClicked={setWithdrawModalOpen}
          sw={smartWalletAddress}
          ethersProvider={ethersProvider}
          usdcBalance={usdcBalance}
          type="Withdraw"
        />
      )}
    </div>
  );
};

export default BalanceSummary;