import React, { FC, useContext, useEffect, useState } from "react";
import USDC_ABI from "../../contracts/usdc.json";
import { ethers } from "ethers";
import { CompanyDataType } from "constants/constants";
import { ErrorMessages } from "./ErrorMessages";
import { USDC_ADDRESS } from "contracts";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import { executeSwap } from "./trade.helpers";
import { getOutQuote, getQuote } from "./getQuote";
import spaceXABI from "../../contracts/spacex.json";
import ActionSelector from "./ActionSelect";
import CustomSelect from "./CustomSelect";
import DenominationSelector from "./DenominationSelector";
import TradeWidgetInput from "./TradeWidgetInput";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { ABI, PrimaryInvestmentType } from "types";
import { useLocation } from "react-router-dom";
import SharePriceContext from "sharePriceContext";
import BlockChainContext from "blockchainContext";
import { TransactionReceipt, usePrivy, User } from "@privy-io/react-auth";
import PoolBalance from "./getPoolBalance";
import { checkMaxAvailable } from "./checkMaxAvailable";

import { generateDollarShareLabels, determineIfButtonIsDisabled2 } from "./trade-ui-helpers";
import TradeWidgetLoader from "./TradeWidgetLoader";
import TradeWidgetDollarShareLabels from "./TradeWidgetDollarShareLabels";
import UserBalances from "./UserBalances";

interface TradeWidgetProps {
  smartWalletAddress: string;
  companyBalances: { [key: string]: string };
  usdcBalance: string;
  provider: ethers.providers.ExternalProvider;
  setUsdcBalance: React.Dispatch<React.SetStateAction<string>>;
  company: CompanyDataType | PrimaryInvestmentType | null;
}
function getCompany(companyData: CompanyDataType[], uri: string) {
  return companyData.find((item: CompanyDataType) => item.uri === uri);
}

const TradeWidget: FC<TradeWidgetProps> = ({
  smartWalletAddress,
  usdcBalance,
  companyBalances,
  setUsdcBalance,
  company
}) => {
  const location = useLocation();
  const currentUri = location.pathname;
  const { user } = usePrivy();
  const [loading, setIsLoading] = useState(false);
  const [poolBalance, setPoolBalance] = useState<string>("");
  const [poolUsdcBalance, setPoolUsdcBalance] = useState<string>("");
  const [action, setAction] = useState<string>("buy");
  const [inputValue, setInputValue] = useState("");
  const [receipt, setReceipt] = useState<any>();
  const [denomination, setDenomination] = useState<string>("dollars");
  const [pricePerShare, setPPS] = useState<string>("0");
  const [sharesAmount, setSharesAmount] = useState<string>("");
  const [usdcAmount, setUsdcAmount] = useState<string>("");
  const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
  const { client } = useSmartWallets();
  const { companyData } = useContext(SharePriceContext);
  const co = getCompany(companyData, currentUri);
  const { setCompanyBalances, ethersProvider, provider } = useContext(BlockChainContext);
  const [selectedCompany, setSelectedCompany] = useState<CompanyDataType>(co ? co : companyData[0]);

  useEffect(() => {
    const idx = companyData.findIndex((co: CompanyDataType) => co.title == selectedCompany.title);
    if (!currentUri.includes("primary")) {
      if ((action === "buy" && denomination === "dollars") || (action === "sell" && denomination === "shares")) {
        getQuote(provider, inputValue, idx, selectedCompany, setSharesAmount, setUsdcAmount, action, setPPS);
      } else {
        getOutQuote(provider, inputValue, idx, selectedCompany, setSharesAmount, setUsdcAmount, action, setPPS);
      }
    }
  }, [selectedCompany, action, denomination, inputValue, provider, companyData, currentUri]);

  useEffect(() => {
    const getPoolBalance = async () => {
      if (!ethersProvider || !selectedCompany.address || !selectedCompany.abi || !selectedCompany.tradePool) return;
      //get the shares available in the pool
      const companyContract = new ethers.Contract(selectedCompany.address, spaceXABI, ethersProvider);
      const poolBalanceRaw = await companyContract.balanceOf(selectedCompany.tradePool);
      setPoolBalance(Math.max(0, Number(ethers.utils.formatUnits(poolBalanceRaw, 6)) - 0.5).toString());

      //get the cash available in the pool
      const usdcContract = new ethers.Contract(USDC_ADDRESS, USDC_ABI, ethersProvider);
      const poolUsdcBalanceRaw = await usdcContract.balanceOf(selectedCompany.tradePool);
      setPoolUsdcBalance(Math.max(0, Number(ethers.utils.formatUnits(poolUsdcBalanceRaw, 6)) - 100).toString());
    };
    getPoolBalance();
  }, [ethersProvider, selectedCompany, poolBalance]);

  // reset input values when denomination changes (still a bug when. Need to click shares or dollars again for a full reset)
  useEffect(() => {
    setSharesAmount("");
    setUsdcAmount("");
    setInputValue("");
  }, [denomination]);

  useEffect(() => {
    if (inputValue === "0" || inputValue === "") {
      setPPS("0");
      setSharesAmount("");
      setUsdcAmount("");
    }
  }, [action, selectedCompany]);

  const idx = companyData.findIndex((co: CompanyDataType) => co.title == selectedCompany.title);

  const isButtonDisabled = determineIfButtonIsDisabled2(
    inputValue,
    action,
    denomination,
    usdcBalance,
    companyBalances,
    poolUsdcBalance,
    poolBalance,
    selectedCompany,
    usdcAmount,
    idx,
    sharesAmount
  );
  const fetchBalances = async () => {
    if (!ethersProvider || !smartWalletAddress) return;

    //get USDC
    const usdcContract = new ethers.Contract(USDC_ADDRESS, USDC_ABI, ethersProvider);
    const usdcBalanceRaw = await usdcContract.balanceOf(smartWalletAddress);
    setUsdcBalance(ethers.utils.formatUnits(usdcBalanceRaw, 6));
    const poolUsdcBalanceRaw = await usdcContract.balanceOf(selectedCompany.tradePool);
    setPoolUsdcBalance(ethers.utils.formatUnits(poolUsdcBalanceRaw, 6));

    // set balances of tokens
    const newBalances: { [key: string]: string } = {};
    for (const company of companyData) {
      if (company.abi && company.address) {
        const companyContract = new ethers.Contract(
          company.address,
          company.abi as ethers.ContractInterface,
          ethersProvider
        );
        const companyBalanceRaw = await companyContract.balanceOf(smartWalletAddress);
        newBalances[company.title] = ethers.utils.formatUnits(companyBalanceRaw, 6);
        // const poolBalanceRaw = await companyContract.balanceOf(company.tradePool);
      }
    }
    const companyContract = new ethers.Contract(selectedCompany.address, spaceXABI, ethersProvider);
    const poolBalanceRaw = await companyContract.balanceOf(selectedCompany.tradePool);
    setPoolBalance(ethers.utils.formatUnits(poolBalanceRaw, 6));
    console.log("Setting Balances");
    setCompanyBalances(newBalances);
  };

  async function swap() {
    setIsLoading(true);
    const tokenOut = action == "buy" ? selectedCompany.address : USDC_ADDRESS;
    const address = action == "buy" ? USDC_ADDRESS : selectedCompany.address;

    // Determine the correct input value based on action and denomination
    const swapInputValue =
      action === "buy" && denomination === "shares"
        ? usdcAmount[idx]
        : action === "sell" && denomination === "dollars"
          ? sharesAmount[idx]
          : inputValue;
    console.log("EXE SWAP", address);
    try {
      if (!ethersProvider) return;
      await executeSwap(
        user as User,
        client,
        smartWalletAddress,
        ethersProvider,
        swapInputValue,
        address,
        selectedCompany.abi as ABI,
        tokenOut,
        action,
        selectedCompany.ticker,
        setIsLoading,
        setReceipt
      );
      console.log("swapInputValue", swapInputValue);
      // Wait for 2 seconds after swap completes
      setTimeout(() => {
        fetchBalances();
        //setInputValue("");
      }, 2000);
      setTransactionSuccess(true);
    } catch (error) {
      console.error("Error during swap execution:", error);
    }
  }

  const errorMessageProps = {
    isButtonDisabled,
    action,
    denomination,
    inputValue,
    usdcBalance,
    usdcAmount,
    idx,
    selectedCompany,
    sharesAmount,
    companyBalances,
    poolUsdcBalance,
    poolBalance
  };
  const tradeWidgetDollarShareLabelsProps = { action, companyData, idx, pricePerShare };
  const confirmationModalProps = {
    setTransactionSuccess,
    selectedCompany,
    isAnimating: true,
    action,
    receipt,
    denomination,
    setInputValue
  };
  return (
    <div className="col-span-1 bg-white p-4 relative rounded-[8px]">
      <TradeWidgetLoader loading={loading} />
      {transactionSuccess ? <ConfirmationModal {...confirmationModalProps} /> : null}
      {company && currentUri.includes("primary") ? null : <ActionSelector action={action} setAction={setAction} />}
      <div style={{ backgroundColor: "#ECF1FC" }} className="p-4 rounded-[8px] mt-4">
        <span className="text-black font-bold text-sm">
          {company && currentUri.includes("primary") ? "ALLOCATE" : action.toLocaleUpperCase()}
        </span>
        {company && currentUri.includes("primary") ? (
          <div className="flex items-center gap-2">
            <img src={company.image} alt={company.title} className="w-6 h-6 rounded-full" />
            <span>{company.title}</span>
          </div>
        ) : (
          <CustomSelect items={companyData} setSelectedCompany={setSelectedCompany} selectedCompany={selectedCompany} />
        )}
        <div className="mt-8 text-black font-bold text-sm"> Amount </div>
        <div className="flex flex-row pt-2 rounded-[8px]">
          <TradeWidgetInput
            inputValue={inputValue}
            setInputValue={setInputValue}
            denomination={denomination}
            className={
              isButtonDisabled && !(inputValue === "" || /^0(\.0{1,6})?$/.test(inputValue)) ? "text-red-500" : ""
            }
          />
          <div className="mx-1" />
          {company && currentUri.includes("primary") ? (
            "Shares"
          ) : (
            <>
              <DenominationSelector denomination={denomination} setDenomination={setDenomination} />
            </>
          )}
        </div>
        <div>
          <PoolBalance action={action} poolUsdcBalance={poolUsdcBalance} poolSharesBalance={poolBalance} />
        </div>
      </div>
      <TradeWidgetDollarShareLabels {...tradeWidgetDollarShareLabelsProps} />
      <div className="my-4 border-t border-gray-300 border-opacity-50" />
      {generateDollarShareLabels(action, denomination, sharesAmount, usdcAmount, idx, companyData)}

      {companyData[idx].sharePrice < 0 && action == "buy" ? (
        <button disabled={true} style={{ backgroundColor: "#D0DBF9" }} className="w-full p-2 mt-8 text-white">
          Sold Out
        </button>
      ) : (
        <button
          disabled={isButtonDisabled}
          onClick={() => swap()}
          style={{ backgroundColor: isButtonDisabled ? "#D0DBF9" : "#436FE6", borderRadius: "8px" }}
          className="w-full p-2 mt-8 text-white"
        >
          {action === "buy" ? "Buy Now" : "Sell Now"}
        </button>
      )}
      <ErrorMessages {...errorMessageProps} />
      <div className="mt-4">
        <UserBalances action={action} usdcBalance={usdcBalance} foundBalance={companyBalances[selectedCompany.title]} />
      </div>
    </div>
  );
};
export default TradeWidget;
