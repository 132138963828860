import { formatToDollar } from "common";

interface  PoolBalanceProps {
  action: string;
  poolUsdcBalance: any;
  poolSharesBalance: any;
}



const  PoolBalance: React.FC<PoolBalanceProps> = ({ action, poolUsdcBalance, poolSharesBalance }) => {
  if (action === "buy") {
    return (
      <div className="mt-3 text-left text-xs text-gray-600">
        Shares Available: {poolSharesBalance}
        {/* <div className="mt-2">Available Shares: {poolBalance}</div> */}
      </div>
    );
  } else {
    return (
      <div className="mt-3 text-left text-xs text-gray-600">
        Liqudity Pool: {formatToDollar(Number(poolUsdcBalance).toFixed(2))}
      </div>
    );
  }
};

export default PoolBalance;





// import { ethers } from "ethers";
// import {ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql} from "@apollo/client";

// export const getPoolBalance = async (ethersProvider: any, idx: number, CompanyData: any[]) => {
//   try {
//     const tokenAddress = CompanyData[idx].address;
//     const poolAddress = CompanyData[idx].tradePool;
//     const contract = new ethers.Contract(tokenAddress, CompanyData[idx].abi, ethersProvider);
//     const balance = await contract.balanceOf(poolAddress);
//     return balance;
//   } catch (error) {
//     console.error(`Error getting pool balance for ${CompanyData[idx].title}:`, error);
//     return ethers.BigNumber.from(0);
//   }
// };





// // 1. Define the Subgraph endpoint
// const client = new ApolloClient({
//   uri: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3",
//   cache: new InMemoryCache(),
// });

// // 2. GraphQL query to get total token amounts locked in the pool
// //    "id" must be the pool's address in lowercase.
// const POOL_QUERY = gql`
//   query PoolData($id: String!) {
//     pool(id: $id) {
//       id
//       token0 {
//         symbol
//         decimals
//       }
//       token1 {
//         symbol
//         decimals
//       }
//       totalValueLockedToken0
//       totalValueLockedToken1
//     }
//   }
// `;

// // 3. A React component that fetches the pool's TVL (token0/token1)
// function PoolLiquidity({ poolId }) {
//   const { loading, error, data } = useQuery(POOL_QUERY, {
//     variables: { id: poolId.toLowerCase() },
//   });

//   if (loading) return <p>Loading pool data...</p>;
//   if (error) return <p>Error: {error.message}</p>;

//   // If the subgraph can’t find the pool, data.pool will be null
//   if (!data || !data.pool) {
//     return <p>No pool data found for this address: {poolId}</p>;
//   }

//   const { pool } = data;
//   const {
//     token0,
//     token1,
//     totalValueLockedToken0,
//     totalValueLockedToken1,
//   } = pool;

//   return (
//     <div style={{ border: "1px solid #ccc", padding: "1rem", margin: "1rem" }}>
//       <h2>Pool: {poolId}</h2>
//       <p>
//         <strong>{token0.symbol} locked:</strong> {totalValueLockedToken0}
//       </p>
//       <p>
//         <strong>{token1.symbol} locked:</strong> {totalValueLockedToken1}
//       </p>
//       <p style={{ fontSize: "0.9rem", color: "#666" }}>
//         (Values as reported by the Uniswap V3 Subgraph)
//       </p>
//     </div>
//   );
// }

// // 4. Wrap your app in ApolloProvider and pass the pool address you want
// export default function App() {
//   // Address of your pool
//   // e.g. 0x4C8Db813184b80d20BC32Baf1575a21fe3E345CB
//   const POOL_ADDRESS = "0x4C8Db813184b80d20BC32Baf1575a21fe3E345CB";

//   return (
//     <ApolloProvider client={client}>
//       <div style={{ maxWidth: 600, margin: "auto" }}>
//         <h1>Uniswap V3 Pool Liquidity</h1>
//         <PoolLiquidity poolId={POOL_ADDRESS} />
//       </div>
//     </ApolloProvider>
//   );
// }

