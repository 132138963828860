import { FC, useState, useContext, useEffect } from "react";
import { usePrivy, useFundWallet } from "@privy-io/react-auth";
import { FaIdCard, FaWallet, FaPhone } from "react-icons/fa";
import BlockChainContext from "blockchainContext";
import PrinxContext from "prinxDataContext";
import { getPrinxUser } from "../calls"; // Import the function

import FundingModal from "./Modals/FundingModal";
import KYCModal from "./Modals/KYCModal";
import { PrinxContextProvider } from "prinxDataContext";
const styles = {
  wrap: {
    borderRadius: "8px",
    position: "relative"
  },
  group: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px"
  },
  step: {
    backgroundColor: "white",
    flex: 1,
    padding: "10px",
    borderRadius: "8px",
    textAlign: "center",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    gap: "5px"
  },
  icon: {
    fontSize: "20px",
    color: "#007bff"
  }
} as const;
const getUserEmail = (user: any) => {
  if (!user) return;
  if (user.email) return user.email.address;
  if (user.google) return user.google.email;
};

const determineRemaingingSteps = (user: any, usdcBalance: string, prinxUser?: any) => {
  const phone = user?.linkedAccounts.find((account: any) => account.type === "phone");
  const stepsRemaning = [];
  if (!phone) stepsRemaning.push("phone");
  //for now just add kyc and fund
  if (prinxUser && !prinxUser.attestation) {
    stepsRemaning.push("kyc");
  }
  if (usdcBalance == "0") {
    stepsRemaning.push("fund");
  }
  return stepsRemaning;
};
interface BalanceSummaryProps {
  usdcBalance: string;
}

const UserSteps: FC<BalanceSummaryProps> = ({ usdcBalance }) => {
  const { user, linkPhone } = usePrivy();
  const [steps, setSteps] = useState(determineRemaingingSteps(user, usdcBalance));
  const [fundingClicked, setFundingClicked] = useState(false);
  const [kycClicked, setKycCicked] = useState(false);
  const { prinxUser, setPrinxUser } = useContext(PrinxContext);
  console.log("Received Prinx User in User Steps", prinxUser);

  // this is setting up the funding for the clients wallet. Uses Privy native options
  const { smartWalletAddress } = useContext(BlockChainContext);
  const { fundWallet } = useFundWallet();

  useEffect(() => {
    try {
      const steps = determineRemaingingSteps(user, usdcBalance, prinxUser);
      setSteps(steps); // Call function after fetch
      if (prinxUser && !prinxUser.attestation && user && user.id && user.hasAcceptedTerms) {
        // this timeout is a way of handling the timing of modals popping up.
        // Privy accepting auth terms pops up after log in and the animation takes about this long.
        setTimeout(() => {
          setKycCicked(true); // if privy user exists but no attestation
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    }
  }, [prinxUser]);

  useEffect(() => {
    setSteps(determineRemaingingSteps(user, usdcBalance, prinxUser)); // Call function after fetch
  }, [usdcBalance]);

  useEffect(() => {
    async function fetchUser() {
      const email = getUserEmail(user);
      try {
        const userData = await getPrinxUser(email);
        setSteps(determineRemaingingSteps(user, usdcBalance, userData)); // Call function after fetch
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    }

    fetchUser();
  }, [prinxUser]);
  const handleFundWallet = async () => {
    try {
      console.log("Smart Wallet Address is", smartWalletAddress);
      // window.open(
      //   `https://pay.coinbase.com/buy/select-asset?appId=e12c3721-6d1f-4f36-9c44-625aea185746&addresses={${smartWalletAddress}:["base"]}`
      // );
      window.open(
        `https://pay.coinbase.com/buy/select-asset?appId=e12c3721-6d1f-4f36-9c44-625aea185746&addresses={"${smartWalletAddress}":["base"]}&assets=["USDC"]`
      );
    } catch (error) {
      console.error("Error in funding wallet:", error);
    }
  };

  const handleClick = () => {
    setFundingClicked((prev) => !prev); // Toggle the boolean
  };

  return (
    <div style={styles.wrap} className="mb-2">
      {kycClicked ? <KYCModal isAnimating={true} onClose={setKycCicked} setParentPrinxUser={setPrinxUser} /> : null}
      {/* {fundingClicked ? <FundingModal setFundingClicked={setFundingClicked} sw={smartWalletAddress} /> : null} */}
    </div>
  );
};

export default UserSteps;
