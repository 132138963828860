import React from "react";

const LoadingTableSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse w-full">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex w-full items-center border-t py-3">
          {/* Firm Column (50%) */}
          <div className="w-[50%] flex items-center">
            <div className="p-1 mr-2 flex-shrink-0">
              <div className="w-10 h-10 min-w-10 min-h-10 bg-gray-300 rounded-full"></div>
            </div>
            <div className="flex flex-col w-full">
              <div className="h-4 w-2/3 bg-gray-300 rounded mb-1"></div>
              <div className="h-3 w-1/2 bg-gray-200 rounded"></div>
            </div>
          </div>

          {/* Allocation Column (50%) */}
          <div className="w-[50%] flex justify-end pr-4">
            <div className="h-4 w-1/3 bg-gray-300 rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadingTableSkeleton;
