import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { burnNFT } from "../../calls";
import { usePrivy } from "@privy-io/react-auth";

const AdminUser: FC = () => {
  const location = useLocation();
  const user = location.state?.user;
  const { user: auth } = usePrivy();

  console.log("User?", user);
  const [showData, setShowData] = useState(false);
  const [showHoldings, setShowHoldings] = useState(false);
  return (
    <div
      style={{ backgroundColor: "rgb(245, 247, 253)", minHeight: "100vh" }}
      className="bg-white p-4 flex-col h-full w-full"
    >
      <div className="text-m">Admin</div>

      <hr />
      {/* Pass a guaranteed valid selectedCompany */}
      <div className="mt-8">
        <div className="text-m">User</div>
        <ul className="space-y-2">
          <li>
            <strong>Email:</strong> {user.email}
          </li>
          <li>
            <strong>Phone:</strong> {user.phone}
          </li>
          <li>
            <div className="flex flex-row">
              <strong className="mr-3">Attestation</strong> {user.attestation ? "true" : "false"}
              {user.nft ? (
                <button
                  onClick={() => burnNFT(user.nft, auth)}
                  className="bg-red-500 text-white mx-3 px-3 py-1 rounded text-sm flex-end"
                >
                  Burn NFT
                </button>
              ) : null}
            </div>
          </li>
          <li>
            <strong>Coin Balance:</strong> {user.coinBalance}
          </li>
          <li>
            <strong>Smart Wallet Address:</strong> {user.smart_wallet}
          </li>
          <li>
            <strong>Account ID:</strong> {user._id}
          </li>

          <li>
            <button className="bg-blue-500 text-white px-3 py-1 rounded text-sm" onClick={() => setShowData(!showData)}>
              {showData ? "Hide Data" : "Show Data"}
            </button>
            {showData && <pre className="bg-gray-100 p-2 rounded mt-2">{JSON.stringify(user.data, null, 2)}</pre>}
          </li>

          {/* Collapsible Holdings Section */}
          <li>
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded text-sm"
              onClick={() => setShowHoldings(!showHoldings)}
            >
              {showHoldings ? `Hide Holdings (${user.holdings.length})` : `Show Holdings (${user.holdings.length})`}
            </button>
            {showHoldings && (
              <ul className="grid grid-cols-2 gap-2 mt-2">
                {user.holdings.slice(0, 10).map((holding: any, index: number) => (
                  <li key={index} className="border p-2 rounded bg-gray-50">
                    {JSON.stringify(holding, null, 2)}
                  </li>
                ))}
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminUser;
