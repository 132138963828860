import React from "react";

const LoadingSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-4 p-4 w-full max-w-md">
      {/* Loading header */}
      <div className="w-3/4 h-6 bg-gray-300 rounded-md animate-pulse"></div>

      {/* Loading text lines */}
      <div className="w-full h-4 bg-gray-300 rounded-md animate-pulse"></div>
      <div className="w-5/6 h-4 bg-gray-300 rounded-md animate-pulse"></div>
      <div className="w-4/6 h-4 bg-gray-300 rounded-md animate-pulse"></div>

      {/* Loading button */}
      <div className="w-1/3 h-8 bg-gray-300 rounded-md animate-pulse"></div>
    </div>
  );
};

export default LoadingSkeleton;
