import { ethers } from "ethers";
import { encodeFunctionData } from "viem";
import { USDC_ADDRESS } from "../../contracts/index";
import USDC_ABI from "../../contracts/usdc.json";

// Define the parameters for the withdraw function
export interface SendWithdrawParams {
  client: any;
  ethersProvider: any;
  setIsLoading: any;
  outputWalletAddress: string;
  withdrawAmount: string;
}

// Define the structure of the withdraw result
export interface WithdrawResult {
  txHash: string;
  withdrawAmount: string;
  fromAddress: string;
  toAddress: string;
}

async function sendWithdraw({
  client,
  ethersProvider,
  setIsLoading,
  outputWalletAddress,
  withdrawAmount,
}: SendWithdrawParams): Promise<WithdrawResult> {
  // Validate the client and provider.
  if (!client || !ethersProvider) {
    console.error("Invalid client or provider");
    return {
      txHash: "",
      withdrawAmount: "0",
      fromAddress: "",
      toAddress: "",
    };
  }

  try {
    // Parse the provided withdraw amount (USDC uses 6 decimals)
    const withdrawAmountBigNumber = ethers.utils.parseUnits(withdrawAmount, 6);

    // Build the call object for the transfer function on the USDC contract.
    const call = {
      to: USDC_ADDRESS,
      data: encodeFunctionData({
        abi: USDC_ABI,
        functionName: "transfer",
        args: [outputWalletAddress, withdrawAmountBigNumber],
      }),
    };

    // Build the transaction parameters. Note that we only have one call in this transaction.
    const txParams = {
      account: client?.account,
      calls: [call],
    };

    // Send the transaction using the client
    const txHash = await client.sendTransaction(txParams);
    console.log("Withdraw Transaction Hash:", txHash);
    setIsLoading(false);

    // Optionally, wait for and retrieve the transaction receipt.
    await ethersProvider.getTransactionReceipt(txHash);

    return {
      txHash,
      withdrawAmount,
      fromAddress: client?.account,
      toAddress: outputWalletAddress,
    };
  } catch (error) {
    console.error("Error in withdraw transaction:", error);
    setIsLoading(false);

    return {
      txHash: "",
      withdrawAmount: "0",
      fromAddress: "",
      toAddress: "",
    };
  }
}

export default sendWithdraw;
