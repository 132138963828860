const questions = [
  {
    question: "What is your goal in investing with Prinx?",
    warn: "Prinx investments have high risk and lower liquidity.  Your answer indicates you are looking for a different type of investment that is not offered on Prinx.",
    fullWidthAnswer: true,
    acceptMulti: true,
    requiredAnswers: [],
    disallowed: [1, 2],
    answers: [
      "I'm looking for exposure to earlier stage private investments with a greater potential for return to complement my overall portfolio",
      "I know that investments on Prinx are unlikely to fail and I am attracted to these risk-free but high-reward opportunities",
      "I need a quick return on my investment so I expect fast liquidity and a broad public market for any investments I make through Prinx",
      "I'd like the opportunity to invest in private company opportunities that have historically been unavailable to me"
    ]
  },
  // {
  //   question:
  //     "Do you understand that by making an investment, you agree that Prinx or other investment advisors have sole discretion to determine the timing of distributions and certain other decisions that can materially impact the value of your investment?",
  //   warn: "Prinx primary rounds may take longer than expected close.  We require our users to understand and agree to this.",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [],
  //   answers: ["Yes", "No"]
  // },
  // {
  //   question:
  //     "Do you understand that Prinx does not control your Prinx embedded wallet, and only you control your private keys?",
  //   warn: "You must be careful with your wallet.  For security, you are in charge of your keys, not us.  If you lose them, we cannot recover all assets.",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [],
  //   answers: ["Yes", "No"]
  // },
  // {
  //   question:
  //     "Prinx wallets are non-custodial, and not controlled by Prinx. What happens to assets in your Prinx wallet if you lose access to your Prinx account",
  //   warn: "You must be careful with your wallet.  For security, you are in charge of your keys, not us.  If you lose them, we cannot recover all assets.",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [],
  //   answers: [
  //     "Assets in my wallet are lost forever since Prinx does not have access to the private keys",
  //     "Prinx can recover my assets for me and transfer them to a new account"
  //   ]
  // },
  // {
  //   question:
  //     "Choose one of the following that best describes your expectations for your current and future income sources",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [],
  //   disallowed: [0],
  //   answers: [
  //     "I expect my income to decrease",
  //     "I expect my income to remain about the same",
  //     "I expect my income to increase"
  //   ]
  // },
  // {
  //   question: "How many investments in startups, seed rounds or private companies have you made within the last year?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [],
  //   disallowed: [],
  //   answers: ["0", "1", "2-5", "5+"]
  // },
  // {
  //   question: "What is the worst case scenario if a start up you invested in fails?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [],
  //   answers: [
  //     "I will lose all of my investment because the project never launched a token, there was a fatal security flaw, there was never any liquidity for the asset, or the associated project simply fails to attract attention and adoption",
  //     "I bought in an early round so | will be able to sell at break-even prices",
  //     "I will lose some of my investment when I sell the token for a loss"
  //   ]
  // },
  // {
  //   question:
  //     "Do you understand that you are unlikely to be able to see any return at all until a liquidity event occurs for the underlying asset the token is invested in?",
  //   warn: "Prinx offers private investments which do not have the same liquidity as public ones.  The valuation of your investment changes during a liquidity event, such as an IPO.",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [],
  //   answers: ["Yes", "No"]
  // },
  {
    question: "How much do you expect to invest through Prinx?",
    warn: "",
    fullWidthAnswer: false,
    acceptMulti: false,
    requiredAnswers: [],
    disallowed: [],
    answers: ["$0 to $200", "$200 to $2,000", "$2,000 to $10,000", "$10,000+"]
  },
  {
    question: "Which of the following companies would you be most interested in investing in? Select all that apply.",
    warn: "",
    fullWidthAnswer: false,
    acceptMulti: true,
    requiredAnswers: [],
    disallowed: [],
    answers: [
      "Epic Games",
      "Riot Games",
      "Niantic",
      "Discord",
      "Valve",
      "Canva",
      "Space-X",
      "Open AI",
      "Perplexity",
      "Stripe",
      "Athletic Brewing",
      "Liquid Death",
      "Aldi",
      "Trafigura Group",
      "CW Group",
      "Studio Ghibli"
    ]
  },
  // {
  //   question: "How long do you expect to hold your investments made on Prinx?",
  //   warn: "Assets held for this duration are unlikely to see a profit.  Prinx is not the best fit for someone with this investment timeline",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [],
  //   disallowed: [0],
  //   answers: ["A few weeks", "A couple of months", "At least a year", "Multiple years"]
  // },
  // {
  //   question: "Select all applicable sources of income",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: true,
  //   requiredAnswers: [],
  //   disallowed: [],
  //   answers: ["Salary", "Capital Income", "Self-employed", "Other"]
  // },
  // {
  //   question: "Have you bought secondary private shares before?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [],
  //   disallowed: [],
  //   answers: ["Yes", "No"]
  // },
  // {
  //   question:
  //     "Do you understand that digital assets may not be regulated in your jurisdiction, and not covered by any financial compensation schemes?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [],
  //   answers: ["Yes", "No"]
  // },
  // {
  //   question: "How much do you expect to invest in startups, seed rounds or private companies this year?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [],
  //   disallowed: [],
  //   answers: ["Less than $10,000", "$10,000 to $25,000", "$25,000 to $50,000", "$50,000+"]
  // },
  // {
  //   question: "What is the value of your net assets, not including the value of your primary home?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [],
  //   disallowed: [],
  //   answers: ["Less than $500,000", "$500,000 to $1,000,000", "$1,000,000 to $5,000,000", "$5,000,000+ |"]
  // },
  // {
  //   question: "Have you invested in startups, seed rounds or private companies before?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [],
  //   disallowed: [],
  //   answers: ["Yes", "No"]
  // },
  // {
  //   question:
  //     "Do you understand that private assets are highly risky, volatile and there may never be liquidity sufficient to sell your investment?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [],
  //   answers: ["Yes", "No"]
  // },
  // {
  //   question: "What was your average annual income over the last two years?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [],
  //   disallowed: [],
  //   answers: ["Less than $50,000", "$50,000 to $200,000", "$200,000 to $1,000,000", "$1,000,000+"]
  // },
  // {
  //   question:
  //     "Do you understand that the assets you can access on Prinx constitute a share in a company just holds the underlying pre-IPO shares?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [1],
  //   answers: ["Yes", "No"]
  // },
  // {
  //   question:
  //     "Do you understand that these assets do NOT give you voting rights and do NOT give you access sensitive or private corporate information?",
  //   warn: "",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [1],
  //   answers: ["Yes", "No"]
  // },
  // {
  //   question:
  //     "Do you understand that Prinx has the right to pause trading at any time in order for the pre-IPO company to conduct a capital event or at Prinx's discretion?",
  //   warn: "In the event that a company announces privately plans to IPO, or raise another round, Prinx may freeze trades to mantain an equal playing field for all investors.",
  //    fullWidthAnswer: false,
  // acceptMulti: false,
  //   requiredAnswers: [0],
  //   disallowed: [1],
  //   answers: ["Yes", "No"]
  // },
  {
    question:
      "Please carefully review the information below before submitting your qualification. By submitting your qualification, you understand and accept that:",
    warn: "You must understand and accept all the items",
    fullWidthAnswer: true,
    acceptMulti: true,
    requiredAnswers: [0, 1, 2, 3, 4],
    disallowed: [],
    answers: [
      "The investments which may be available to you on Prinx may expose you to a significant risk of losing all of the money or other property invested",
      "The vehicles in which you invest are generally not registered under the applicable laws of your jurisdiction and will therefore lack the protections afforded to registered vehicles, no regulatory authority has reviewed or approved the promotions or marketing materials, and you possess the knowledge, experience, and expertise to make your own investment decisions and assess the risk of the investment opportunities on Prinx",
      "If you invest, you are responsible for complying with securities laws and you are aware that it is open to you to seek advice from your own attorney, professional advisors, or other authorized persons who specialize in advising on non-mainstream pooled investments",
      "Prinx does not verify all information on the site, and you are responsible for your own diligence",
      "You promise to hold Prinx harmless against any damage that may happen to yourself as a result of your use of Prinx",
      "You understand that you will be permanently banned from Prinx and that you may face legal consequences if you falsely represent your accreditation status"
    ]
  }
];
export default questions;
