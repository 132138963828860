import { FC, useContext, useEffect, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import CustomSelect from "components/Trade/CustomSelect";
import { SharePriceContext } from "sharePriceContext";
import { CompanyDataType } from "constants/constants";
import { getUsersWithCoin } from "calls";
import { ethers } from "ethers";
import UsersByToken from "./UsersByToken";

const Admin: FC = () => {
  const { user } = usePrivy();

  //   if (!companyData || companyData.length === 0) {
  //     return <div>Loading...</div>; // Ensure we have data before rendering
  //   }

  return (
    <div
      style={{ backgroundColor: "rgb(245, 247, 253)", minHeight: "100vh" }}
      className="bg-white p-4 flex-col h-full w-full"
    >
      <div className="text-m">Admin</div>
      <hr />
      {/* Pass a guaranteed valid selectedCompany */}
      <div className="mt-8">
        <UsersByToken />
      </div>
    </div>
  );
};

export default Admin;
