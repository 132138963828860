import React from "react";
import Modal from "react-modal";
import CopyWithTooltip from "./CopyTooltip";

interface ManualDepositModalProps {
  isOpen: boolean;
  onClose: () => void;
  sw: string;
}

const ManualDepositModal: React.FC<ManualDepositModalProps> = ({ isOpen, onClose, sw }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Manual Deposit Modal"
    style={{
      overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
      content: {
        width: "fit-content",
        maxWidth: "90%",
        height: "auto",
        maxHeight: "90vh",
        overflow: "auto",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "1rem",
        borderRadius: "20px",
      },
    }}
  >
    <div className="py-6 px-2 md:px-6 h-[430px] w-full max-w-[360px] md:max-w-[460px]">
      {/* Back and Close buttons for the Manual Deposit modal */}
      <div className="flex justify-between items-center">
        <button 
          onClick={onClose} 
          className="text-lg font-bold text-blue-500 border border-blue-500 rounded px-2 py-1">
          {"Back"}
        </button>
        <button onClick={onClose} className="text-lg font-bold">
          X
        </button>
      </div>
      <h1 className="text-4xl font-semibold mt-8 text-center">Deposit Manually</h1>
      <p className="text-sm mt-6 text-gray-600">
        Copy your wallet address and send USDC from your other crypto wallet or exchange account.
      </p>
      <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded-md mt-12">
        Make sure to send on Base.
      </div>
      <div className="mt-4 bg-gray-100 p-4 rounded-md border border-gray-300">
        <p className="text-xs text-gray-500 mb-1 flex flex-row justify-between">
          <span>Wallet Address</span> <CopyWithTooltip textToCopy={sw} />
        </p>
        <p className="text-xs sm:text-sm md:text-base font-medium">{sw}</p>
      </div>
    </div>
  </Modal>
);

export default ManualDepositModal; 