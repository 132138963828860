import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { formatToDollar } from "common";
import VitalsTable from "./VitalsTable";
import FundingRoundsTable from "./FundingRoundsTable";
import { useWindowSize } from "../../hooks/useWindowSize";
import TradeWidget from "../Trade/TradeWidget";
import DepositWidget from "../Home/depositWidget";

const stockData = [
  { date: new Date("2023-01-01"), price: 100 },
  { date: new Date("2023-02-01"), price: 110 },
  { date: new Date("2023-03-01"), price: 105 },
  { date: new Date("2023-04-01"), price: 115 },
  { date: new Date("2023-05-01"), price: 120 },
  { date: new Date("2023-06-01"), price: 130 }
];

import StockChart from "./StockChart";
import CompanyBio from "./CompanyBio";
import BlockChainContext from "blockchainContext";
import SharePriceContext from "sharePriceContext";
import { CompanyDataType } from "constants/constants";
import { PrimaryInvestmentType } from "types";

const tags = ["Blockchain", "Financial Services", "Cryptocurrency", "Web3"];

function generateTags() {
  return tags.map((tag) => {
    return (
      <div key={tag} className="p-1 mx-2 rounded" style={{ background: "#ECFEB5" }}>
        {tag}
      </div>
    );
  });
}
const Company: React.FC = () => {
  const location = useLocation();
  const currentUri = location.pathname;
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { provider, ethersProvider, usdcBalance, companyBalances, setUsdcBalance, smartWalletAddress } =
    useContext(BlockChainContext);
  const { companyData, primaryCompanyData } = useContext(SharePriceContext);

  let company: PrimaryInvestmentType | CompanyDataType | undefined;
  if (currentUri.includes("primary") && primaryCompanyData.length) {
    company = primaryCompanyData.find((item: PrimaryInvestmentType) => item.uri === currentUri);
  } else if (companyData.length) {
    company = companyData.find((item: CompanyDataType) => item.uri === currentUri);
  }
  const tags = generateTags();
  if (!company || !provider) {
    return <div>Loading</div>;
  } else {
    return (
      <div style={{ background: "#F5F7FD" }} className="">
        <div className={`flex ${isMobile ? "flex-col" : "flex-row"}`}>
          <div className={` ${isMobile ? "w-full" : "w-1/2"}`}>
            <div className="p-4">
              <div onClick={() => navigate(-1)} className="mt-2 text-xs cursor-pointer">
                {"<"} Back
              </div>
              <h1 className="mt-6 text-3xl"> {company.title}</h1>
              {currentUri.includes("primary") && (
                <div className="mt-6 mb-1 text-sm text-gray-500">Estimated Share Price</div>
              )}
              <h4 className="mt-1 text-xl"> {formatToDollar(company.sharePrice.toString())}</h4>
              <div className="flex flex-row">
                {/* {!currentUri.includes("primary") && (
                  <>
                    <h4 className="mt-2 text-m text-red-400"> -$2.50 (-.20%) </h4>
                    <h4 className="mt-2 text-m"> This Week</h4>
                  </>
                )} */}
              </div>
            </div>
            {company && currentUri.includes("primary") ? (
              <div className="p-4">
                Share Type: {company.shareType}
                <div className="relative w-full h-3 bg-gray-200 rounded-full mt-4 p-2">
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      background: "linear-gradient(90deg, #9386F4, #436FE6)",
                      width: `${((company as PrimaryInvestmentType).deposited / ((company as PrimaryInvestmentType).goal + (company as PrimaryInvestmentType).deposited)) * 100}%`
                    }}
                    className="h-full rounded-full"
                  ></div>
                </div>
                ${(company as PrimaryInvestmentType).deposited} / $
                {(company as PrimaryInvestmentType).goal + (company as PrimaryInvestmentType).deposited}
              </div>
            ) : (
              <StockChart data={stockData} />
            )}
          </div>
          <div className={`p-3 ${isMobile ? "w-full" : "w-1/2"}`}>
            {company && currentUri.includes("primary") ? (
              <DepositWidget
                usdcBalance={usdcBalance}
                smartWalletAddress={smartWalletAddress}
                ethersProvider={ethersProvider}
                provider={provider}
                setUsdcBalance={setUsdcBalance}
                company={company as PrimaryInvestmentType}
              />
            ) : (
              <TradeWidget
                usdcBalance={usdcBalance}
                smartWalletAddress={smartWalletAddress}
                provider={provider}
                companyBalances={companyBalances}
                setUsdcBalance={setUsdcBalance}
                company={company}
              />
            )}
          </div>
        </div>
        <div className=" p-4 mt-4 text-2xl"> About </div>
        <div className="p-2 inline-flex flex-row">{tags}</div>
        <div className="p-4">
          <CompanyBio bio={company.bio} />
        </div>
        <div className="p-4">
          <VitalsTable />
        </div>
        <div className="p-4">
          <div className="mt-4 mb-4 text-2xl"> Funding Rounds </div>
          <div className="p-4">
            <FundingRoundsTable />
          </div>
        </div>
      </div>
    );
  }
};

export default Company;
