import { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import coinbase from "../../assets/images/coinbase.png";
import moonpay from "../../assets/images/moonpay.png";
import { useFundWallet } from "@privy-io/react-auth";
import { base } from "viem/chains";
import { MoonPayBuyWidget, MoonPaySellWidget } from "@moonpay/moonpay-react";
import sendWithdraw from "./withdrawFunction";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import WithdrawConfirmation from "./WithdrawConfirmation";
import ManualWithdraw from "./ManualWithdraw";
import { FaCopy } from "react-icons/fa";
import CopyWithTooltip from "./CopyTooltip";
import ManualDepositModal from "./ManualDepositModal";
import ManualWithdrawModal from "./ManualWithdrawModal";
import { generateCDPToken } from "calls";
import PrinxContext from "prinxDataContext";
Modal.setAppElement("#root");

// Custom styles to center the modal.
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "0",
    border: "none",
    background: "none"
  }
};

interface FundingModalProps {
  setFundingClicked: Dispatch<SetStateAction<boolean>>;
  sw: string;
  ethersProvider: any;
  usdcBalance: string;
  type: "Deposit" | "Withdraw"; // Determines which view to render
}

const FundingModal: React.FC<FundingModalProps> = ({ setFundingClicked, sw, ethersProvider, usdcBalance, type }) => {
  // Always open when this component mounts.
  const [modalIsOpen, setModalIsOpen] = useState(true);
  // Remove selection state because we no longer need to choose between modes.
  const [moonPayVisible, setMoonPayVisible] = useState(false);
  const { client } = useSmartWallets();
  const { prinxUser, setPrinxUser } = useContext(PrinxContext);

  // State for showing withdraw confirmation, if needed.
  const [withdrawConfirmationData, setWithdrawConfirmationData] = useState<{
    withdrawAmount: string;
    outputAddress: string;
  } | null>(null);

  const [manualDepositModalOpen, setManualDepositModalOpen] = useState(false);
  const [manualWithdrawModalOpen, setManualWithdrawModalOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
    setFundingClicked(false);
  };

  // Shared function for handling Coinbase (Buy/Deposit)
  const handleCoinbaseClick = async () => {
    try {
      console.log("Smart Wallet Address is", sw);
      window.open(
        `https://pay.coinbase.com/buy/select-asset?appId=e12c3721-6d1f-4f36-9c44-625aea185746&addresses={"${sw}":["base"]}&assets=["USDC"]`
      );
    } catch (error) {
      console.error("Error in funding wallet:", error);
    }
  };

  // New function for handling Coinbase (Sell/Withdraw)
  const handleCoinbaseSellClick = async () => {
    if (!prinxUser) alert("No Prinx User");
    if (!prinxUser) return;
    const response = await generateCDPToken(sw, prinxUser._id);
    console.log("Token", response);
    try {
      const url = process.env.PRINX_CDP_REDIRECT || "http://localhost:3000/offramp-confirmation";
      console.log("Smart Wallet Address is", sw);
      window.open(
        `https://pay.coinbase.com/v3/sell/input?sessionToken=${response.token}&partnerUserId=${prinxUser._id}&redirectUrl=${url}`
      );
      //`https://pay.coinbase.com/v3/sell/input?appId=d7d0012e-0a82-485a-bdbc-af5e92964cb4&partnerUserId=6&addresses={"${sw}":["base"]}&redirectUrl=`
    } catch (error) {
      console.error("Error in Coinbase sell/withdraw:", error);
    }
  };

  // Determine content based on the type prop.
  let content;
  if (type === "Deposit") {
    content = (
      <div className="p-6 bg-white rounded-2xl">
        <div className="flex items-center">
          <button onClick={closeModal} className="ml-auto text-lg font-bold">
            x
          </button>
        </div>

        {/* Main deposit container */}
        <div className="mt-1 p-2 w-[360px] h-[360px]">
          <h1 className="text-4xl font-bold mb-4 text-center">Deposit funds</h1>
          <p className="text-center mb-8"> USDC Balance: ${usdcBalance}</p>
          {/* Deposit Options */}
          <div className="flex flex-col space-y-4">
            {/* Coinbase Deposit Option */}
            <div className="flex justify-start">
              <div className="flex flex-col items-start w-full">
                <span className="italic text-xs text-blue-500 pl-4 mb-1">Recommended</span>
                <div
                  onClick={handleCoinbaseClick}
                  className="w-full border border-blue-500 rounded-lg shadow-sm flex items-center justify-between p-2 cursor-pointer hover:border-2"
                >
                  <img src={coinbase} alt="coinbase" className="mt-1 h-10 w-auto py-2 pl-4" />
                  <span className="text-gray-400 text-sm pr-4">0% Fee</span>
                </div>
              </div>
            </div>

            {/* MoonPay Deposit Option */}
            <div className="flex justify-start">
              <div
                onClick={() => setMoonPayVisible(true)}
                className="w-full border border-gray-300 rounded-lg shadow-sm flex items-center justify-between p-2 cursor-pointer hover:border-2"
              >
                <img src={moonpay} alt="moonpay" className="pl-4 py-2 h-10 w-auto" />
                <span className="text-gray-400 text-sm pr-4">Fee Varies</span>
              </div>
            </div>

            {/* Manual Deposit Option */}
            <div className="flex justify-start pb-4">
              <div
                onClick={() => setManualDepositModalOpen(true)}
                className="w-full border border-gray-300 rounded-lg shadow-sm flex items-center justify-between p-2 cursor-pointer hover:border-2"
              >
                <span className="pl-4 py-2 h-10 w-auto text-base font-medium">Manual Deposit</span>
                <span className="text-gray-400 text-sm pr-4"></span>
              </div>
            </div>
          </div>

          <MoonPayBuyWidget
            variant="overlay"
            baseCurrencyCode="usd"
            baseCurrencyAmount="100"
            visible={moonPayVisible}
          />
        </div>

        {withdrawConfirmationData && (
          <WithdrawConfirmation
            setTransactionSuccess={() => setWithdrawConfirmationData(null)}
            isAnimating={true}
            withdrawAmount={withdrawConfirmationData.withdrawAmount}
            outputAddress={withdrawConfirmationData.outputAddress}
          />
        )}

        {manualDepositModalOpen && (
          <ManualDepositModal
            isOpen={manualDepositModalOpen}
            onClose={() => setManualDepositModalOpen(false)}
            sw={sw}
          />
        )}

        {manualWithdrawModalOpen && (
          <ManualWithdrawModal
            isOpen={manualWithdrawModalOpen}
            onClose={() => setManualWithdrawModalOpen(false)}
            client={client}
            ethersProvider={ethersProvider}
            usdcBalance={usdcBalance}
            onWithdrawSuccess={(data: { withdrawAmount: string; outputAddress: string }) =>
              setWithdrawConfirmationData(data)
            }
            setIsLoading={(loading: boolean) => console.log("Loading:", loading)}
          />
        )}
      </div>
    );
  } else if (type === "Withdraw") {
    content = (
      <div className="p-6 bg-white rounded-2xl">
        <div className="flex items-center">
          <button onClick={closeModal} className="ml-auto text-lg font-bold">
            x
          </button>
        </div>

        {/* Main withdraw container */}
        <div className="mt-1 p-2 w-[360px] h-[360px]">
          <h1 className="text-4xl font-bold mb-4 text-center">Withdraw funds</h1>
          <p className="text-center mb-8"> USDC Balance: ${usdcBalance}</p>

          {/* Withdraw Options */}
          <div className="flex flex-col space-y-4">
            {/* Coinbase Withdraw Option */}
            <div className="flex justify-start">
              <div className="flex flex-col items-start w-full">
                <span className="italic text-xs text-blue-500 pl-4 mb-1">Recommended</span>
                <div
                  onClick={handleCoinbaseSellClick}
                  className="w-full border border-blue-500 rounded-lg shadow-sm flex items-center justify-between p-2 cursor-pointer hover:border-2"
                >
                  <img src={coinbase} alt="coinbase" className="mt-1 h-10 w-auto py-2 pl-4" />
                  <span className="text-gray-400 text-sm pr-4">0% Fee</span>
                </div>
              </div>
            </div>

            {/* MoonPay Withdraw Option */}
            <div className="flex justify-start">
              <div
                onClick={() => setMoonPayVisible(true)}
                className="w-full border border-gray-300 rounded-lg shadow-sm flex items-center justify-between p-2 cursor-pointer hover:border-2"
              >
                <img src={moonpay} alt="moonpay" className="pl-4 py-2 h-10 w-auto" />
                <span className="text-gray-400 text-sm pr-4">Fee Varies</span>
              </div>
            </div>

            {/* Manual Withdraw Option */}
            <div className="flex justify-start pb-4">
              <div
                onClick={() => setManualWithdrawModalOpen(true)}
                className="w-full border border-gray-300 rounded-lg shadow-sm flex items-center justify-between p-2 cursor-pointer hover:border-2"
              >
                <span className="pl-4 py-2 h-10 w-auto text-base font-medium">Manual Withdraw</span>
                <span className="text-gray-400 text-sm pr-4">0 Gas Fee</span>
              </div>
            </div>
          </div>

          <MoonPaySellWidget
            variant="overlay"
            baseCurrencyCode="usd"
            baseCurrencyAmount="100"
            defaultCurrencyCode="eth"
            visible={moonPayVisible}
          />
        </div>

        {withdrawConfirmationData && (
          <WithdrawConfirmation
            setTransactionSuccess={() => setWithdrawConfirmationData(null)}
            isAnimating={true}
            withdrawAmount={withdrawConfirmationData.withdrawAmount}
            outputAddress={withdrawConfirmationData.outputAddress}
          />
        )}

        {manualDepositModalOpen && (
          <ManualDepositModal
            isOpen={manualDepositModalOpen}
            onClose={() => setManualDepositModalOpen(false)}
            sw={sw}
          />
        )}

        {manualWithdrawModalOpen && (
          <ManualWithdrawModal
            isOpen={manualWithdrawModalOpen}
            onClose={() => setManualWithdrawModalOpen(false)}
            client={client}
            ethersProvider={ethersProvider}
            usdcBalance={usdcBalance}
            onWithdrawSuccess={(data: { withdrawAmount: string; outputAddress: string }) =>
              setWithdrawConfirmationData(data)
            }
            setIsLoading={(loading: boolean) => console.log("Loading:", loading)}
          />
        )}
      </div>
    );
  }

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
      {content}
    </Modal>
  );
};

export default FundingModal;
