import { ethers, providers, BigNumber } from "ethers";
import { USDC_ADDRESS } from "../../contracts";
import QuoterV2Json from "@uniswap/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json";
const QUOTER_CONTRACT_ADDRESS = "0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a";
import { BUY_FEE_PERCENTAGE, SELL_FEE_PERCENTAGE } from "../../constants/feeConfig";

const toReadableAmount = (amount: ethers.BigNumber, decimals: number): string => {
  return ethers.utils.formatUnits(amount, decimals);
};

//Quote
export async function getQuote(
  provider: any,
  amountIn: string,
  idx: number,
  selectedCompany: any,
  setSharesAmount: any,
  setUsdcAmount: any,
  action: string,
  setPPS: any
): Promise<void> {
  const tokenOut = selectedCompany.address;
  if (!provider || !amountIn || isNaN(Number(amountIn)) || /^0*\.?0*$/.test(amountIn)) return;

  const ethersProvider = new providers.Web3Provider(provider, 8453);
  const quoterContract = new ethers.Contract(QUOTER_CONTRACT_ADDRESS, QuoterV2Json.abi, ethersProvider);
  //amount that is entered by user
  const amountInBigNumber = ethers.utils.parseUnits(amountIn.toString(), 6);
  console.log("Hitting Quoter", USDC_ADDRESS, tokenOut);
  try {
    if (action === "buy") {
      const buy_feeAmount = amountInBigNumber.mul(BUY_FEE_PERCENTAGE * 100).div(100);
      const amountAfterBuyFee = amountInBigNumber.sub(buy_feeAmount);
      const quoteSharesAmount = await quoterContract.callStatic.quoteExactInputSingle({
        tokenIn: USDC_ADDRESS,
        tokenOut,
        fee: 3000, // Assuming pool fee is 0.3%
        amountIn: amountAfterBuyFee,
        sqrtPriceLimitX96: 0
      });
      const readableBuyQuote = BigNumber.isBigNumber(quoteSharesAmount) ? quoteSharesAmount : quoteSharesAmount?.[0];
      if (readableBuyQuote) {
        setSharesAmount((prevPrices: any) => {
          const updatedPrices = [...prevPrices];
          const sharesAmount = toReadableAmount(readableBuyQuote, 6);
          updatedPrices[idx] = sharesAmount;

          // Convert amountAfterBuyFee to readable format before calculation
          const pricePerShare = (Number(amountIn) / Number(sharesAmount)).toFixed(6);
          setPPS(pricePerShare);

          return updatedPrices;
        });
      } else {
        console.error("Unexpected response format: ", quoteSharesAmount);
      }
    } else if (action === "sell") {
      const sell_feeAmount = amountInBigNumber.mul(SELL_FEE_PERCENTAGE * 100).div(100);
      const amountAfterSellFee = amountInBigNumber.sub(sell_feeAmount);
      const quoteSell = await quoterContract.callStatic.quoteExactInputSingle({
        tokenIn: selectedCompany.address,
        tokenOut: USDC_ADDRESS,
        fee: 3000, // Assuming pool fee is 0.3%
        amountIn: amountAfterSellFee,
        sqrtPriceLimitX96: 0
      });

      const readableSellQuote = BigNumber.isBigNumber(quoteSell) ? quoteSell : quoteSell?.[0];
      if (readableSellQuote) {
        setUsdcAmount((prevPrices: any) => {
          const updatedPrices = [...prevPrices];
          // Set the new value at the specified index
          const usdcAmount = toReadableAmount(readableSellQuote, 6);
          updatedPrices[idx] = usdcAmount;

          const pricePerShare = (Number(usdcAmount) / Number(amountIn)).toFixed(6);
          setPPS(pricePerShare);

          return updatedPrices;
        });
      } else {
        console.error("Unexpected response format: ", quoteSell);
      }
    }
  } catch (error) {
    console.error("Error getting quote: ", error);
    setPPS(-1);
  }
}

//This is the filp file for the quoter
export async function getOutQuote(
  provider: any,
  amountOut: string,
  idx: number,
  selectedCompany: any,
  setSharesAmount: any,
  setUsdcAmount: any,
  action: string,
  setPPS: any
): Promise<void> {
  const tokenOut = selectedCompany.address;
  if (!provider || !amountOut || isNaN(Number(amountOut)) || /^0*\.?0*$/.test(amountOut)) return;

  const ethersProvider = new providers.Web3Provider(provider, 8453);
  const quoterContract = new ethers.Contract(QUOTER_CONTRACT_ADDRESS, QuoterV2Json.abi, ethersProvider);
  try {
    //amount that is entered by user
    const amountInBigNumber = ethers.utils.parseUnits(amountOut.toString(), 6);

    if (action === "buy") {
      const quoteSharesAmount = await quoterContract.callStatic.quoteExactOutputSingle({
        tokenIn: USDC_ADDRESS,
        tokenOut: selectedCompany.address,
        fee: 3000, // Assuming pool fee is 0.3%
        amount: amountInBigNumber,
        sqrtPriceLimitX96: 0
      });

      const readableBuyQuote = BigNumber.isBigNumber(quoteSharesAmount) ? quoteSharesAmount : quoteSharesAmount?.[0];
      if (readableBuyQuote) {
        setUsdcAmount((prevPrices: any) => {
          // Create a new array by copying the previous one
          const updatedPrices = [...prevPrices];
          // Calculate price with fee adjustment (increase by fee amount)
          const priceWithFee = readableBuyQuote.mul(100).div(100 - BUY_FEE_PERCENTAGE * 100);
          // Set the new value at the specified index
          updatedPrices[idx] = toReadableAmount(priceWithFee, 6);

          // Calculate price per share using USDC amount divided by shares requested
          const totalCost = Number(updatedPrices[idx]); // The USDC amount including fees
          const sharesRequested = Number(amountOut); // The shares amount user requested
          const pricePerShare = (totalCost / sharesRequested).toFixed(6);
          setPPS(pricePerShare);

          return updatedPrices; // Return the new array
        });
      } else {
        console.error("Unexpected response format: ", quoteSharesAmount);
      }
    } else if (action === "sell") {
      const quoteSell = await quoterContract.callStatic.quoteExactOutputSingle({
        tokenIn: selectedCompany.address,
        tokenOut: USDC_ADDRESS,
        fee: 3000, // Assuming pool fee is 0.3%
        amount: amountInBigNumber,
        sqrtPriceLimitX96: 0
      });

      const readableSellQuote = BigNumber.isBigNumber(quoteSell) ? quoteSell : quoteSell?.[0];
      if (readableSellQuote) {
        setSharesAmount((prevPrices: any) => {
          // Create a new array by copying the previous one
          const updatedPrices = [...prevPrices];
          // Calculate price with fee adjustment (increase by fee amount)
          const priceWithFee = readableSellQuote.mul(100).div(100 - SELL_FEE_PERCENTAGE * 100);
          // Set the new value at the specified index
          updatedPrices[idx] = toReadableAmount(priceWithFee, 6);

          // Calculate price per share using USDC amount divided by shares
          const usdcRequested = Number(amountOut); // The USDC amount requested
          const sharesNeeded = Number(updatedPrices[idx]); // The shares amount needed including fees
          const pricePerShare = (usdcRequested / sharesNeeded).toFixed(6);
          setPPS(pricePerShare);

          return updatedPrices; // Return the new array
        });
      } else {
        console.error("Unexpected response format: ", quoteSell);
      }
    }
  } catch (error) {
    console.error("Error getting quote: ", error);
  }
}
