import LoadingTableSkeleton from "components/common/Loaders/LoadingTableSkeleton";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

function generateTableTsx(
  companyBalances: { [key: string]: string },
  companyData: any,
  navigate: any,
  companyDataLoaded: boolean,
  companyBalancesLoaded: boolean
) {
  // Filter the rows to include only those with positive balances
  console.log("Company Data", companyData);
  const rows = companyData.map((company: any) => {
    if (company.title in companyBalances && Number(companyBalances[company.title]) > 0) {
      return (
        <div
          key={company.title}
          className="flex w-full items-center border-t py-2 cursor-pointer hover:bg-gray-100"
          onClick={() => navigate(company.uri)}
        >
          {/* Firm Column */}
          <div className="w-[35%] flex items-center">
            <div className="p-1 mr-2 flex-shrink-0">
              <img
                src={company.image}
                alt={company.title}
                className="w-8 h-8 min-w-8 min-h-8 rounded-full object-cover"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs font-medium">{company.title}</div>
              <div className="text-xs text-gray-500">{company.shareType}</div>
            </div>
          </div>

          {/* Share Price Column */}
          <div className="w-[20%] text-xs px-1">
            $
            {company.sharePrice < 0
              ? parseFloat(company.sellSharePrice).toFixed(2)
              : parseFloat(company.sharePrice).toFixed(2)}
          </div>

          {/* Quantity Held Column */}
          <div className="w-[20%] text-xs">{parseFloat(companyBalances[company.title])}</div>

          {/* Estimated Value Column */}
          <div className="w-[25%] text-xs">
            $
            {company.sharePrice < 0
              ? (parseFloat(company.sellSharePrice) * parseFloat(companyBalances[company.title])).toFixed(2)
              : (parseFloat(company.sharePrice) * parseFloat(companyBalances[company.title])).toFixed(2)}
          </div>

          {/* Trade Button Column */}
          <div className="w-[25%]">
            <button className="border rounded px-2 py-1 text-blue-600 border-blue-600">Trade</button>
          </div>
        </div>
      );
    } else {
      return null; // Exclude rows that do not meet the condition
    }
  });

  // Filter out `null` values from rows
  const filteredRows = rows.filter(Boolean);

  // If no rows exist, return a "No allocations yet" message

  if (filteredRows.length === 0 && companyDataLoaded && companyBalancesLoaded) {
    return (
      <div>
        <div className="py-2 text-center text-gray-400">No Positions Yet</div>
      </div>
    );
  }

  if (!companyDataLoaded || !companyBalancesLoaded) {
    return <LoadingTableSkeleton />;
  }

  // Return the filtered rows
  return filteredRows;
}

interface AssetTableProps {
  companyBalances: any;
  companyData: any;
  companyDataLoaded: boolean;
  companyBalancesLoaded: boolean;
}

const AssetTable: FC<AssetTableProps> = ({
  companyBalances,
  companyData,
  companyDataLoaded,
  companyBalancesLoaded
}) => {
  const navigate = useNavigate();
  const tableTsx = generateTableTsx(companyBalances, companyData, navigate, companyDataLoaded, companyBalancesLoaded);
  return (
    <div className="min-w-full table-auto border-collapse">
      <div className="flex w-full border-b pb-2 text-gray-400 text-xs">
        <div className="w-[35%] text-left">Firm</div>
        <div className="w-[20%] text-left px-1">Share Price</div>
        <div className="w-[20%] text-left">Quantity Held</div>
        <div className="w-[25%] text-left">Estimated Value</div>
        <div className="w-[25%] text-left"></div> {/* Empty space for alignment */}
      </div>
      <div className="w-full">{tableTsx}</div>
    </div>
  );
};
export default AssetTable;
