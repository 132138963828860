import { formatToDollar } from "common";

interface UserBalancesProps {
  action: string;
  usdcBalance: any;
  foundBalance: any;
}

const UserBalances: React.FC<UserBalancesProps> = ({ action, usdcBalance, foundBalance }) => {
  if (action === "buy") {
    return (
      <div className="mt-2 text-center text-sm text-gray-600">
        Cash Balance: {formatToDollar(Number(usdcBalance).toFixed(2))}
        {/* <div className="mt-2">Available Shares: {poolBalance}</div> */}
      </div>
    );
  } else {
    return (
      <div className="text-center text-sm text-gray-600">
        Current Balance: {foundBalance} Shares
      </div>
    );
  }
};

export default UserBalances;
