import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ManualWithdraw from "./ManualWithdraw";

interface ManualWithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
  client: any;
  ethersProvider: any;
  usdcBalance: string;
  onWithdrawSuccess: (data: { withdrawAmount: string; outputAddress: string }) => void;
  setIsLoading?: (loading: boolean) => void;
}

const ManualWithdrawModal: React.FC<ManualWithdrawModalProps> = ({
  isOpen,
  onClose,
  client,
  ethersProvider,
  usdcBalance,
  onWithdrawSuccess,
  setIsLoading,
}) => {
  // Set mobile based on a window width threshold
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const checkIsMobile = () => setIsMobile(window.innerWidth < 768);
      checkIsMobile();
      window.addEventListener("resize", checkIsMobile);
      return () => window.removeEventListener("resize", checkIsMobile);
    }
    return undefined;
  }, []);

  // Define custom styles for react-modal based on device size
  const customStyles = isMobile
    ? {
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        content: {
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          maxHeight: "100%",
          overflow: "auto",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          padding: "1rem",
        },
      }
    : {
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        content: {
          width: "fit-content",
          maxWidth: "90%",
          height: "auto",
          maxHeight: "90vh",
          overflow: "auto",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          padding: "1rem",
          borderRadius: "20px",
        },
      };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Manual Withdraw Modal"
      style={customStyles}
    >
      {/* Use responsive utility classes:
          - On mobile: full screen height (h-screen) and full width (max-w-full)  
          - On md and up: fixed height of 480px and max width of 460px */}
      <div className="py-6 px-2 md:px-6 h-screen md:h-[540px] w-full max-w-full md:max-w-[460px]">
        {/* Back and Close buttons for the Manual Withdrawal modal */}
        <div className="flex justify-between items-center">
          <button
            onClick={onClose}
            className="text-lg font-bold text-blue-500 border border-blue-500 rounded px-2 py-1"
          >
            Back
          </button>
          <button onClick={onClose} className="text-lg font-bold">
            X
          </button>
        </div>
        <h1 className="text-4xl font-semibold mt-8 text-center">
          Withdraw Manually
        </h1>
        <p className="text-sm mt-4 text-gray-600">
          Enter the recipient wallet address and the amount you wish to withdraw.
          $1 minimum withdrawal.
        </p>
        <div className="mt-4">
          <ManualWithdraw
            client={client}
            ethersProvider={ethersProvider}
            usdcBalance={usdcBalance}
            onWithdrawSuccess={onWithdrawSuccess}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ManualWithdrawModal; 