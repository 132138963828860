interface TradeWidgetInputProps {
  inputValue: string;
  denomination: string;
  setInputValue: any;
  className?: string;
}
const TradeWidgetInput: React.FC<TradeWidgetInputProps> = ({ inputValue, denomination, setInputValue, className }) => {
  return (
    <>
      <input
        type="number"
        min={denomination === "dollars" ? "1" : "1"}
        step={denomination === "dollars" ? "1" : "1"}
        style={{ height: 50 }}
        value={inputValue}
        placeholder="0"
        onFocus={() => {
          if (inputValue === "0") {
            setInputValue(""); // Clear the input when focused
          }
        }}
        onChange={(e) => {
          const value = e.target.value;
          if (denomination === "dollars") {
            // Allow empty input, decimal point, and valid numbers for dollars
            if (
              value === "" || 
              value === "." || 
              value === "0." || 
              /^\d*\.?\d{0,2}$/.test(value) && !(value === "00" || value === "0" || value === "0.0" || value === "0.00")
            ) {
              setInputValue(value);
            }
          } else {
            // For shares, allow up to 6 decimal places
            if (
              value === "" || 
              value === "." || 
              /^\d*\.?\d{0,6}$/.test(value) && !(value === "00")
            ) {
              setInputValue(value);
            }
          }
        }}
        onBlur={() => {
          if (denomination === "dollars") {
            // Format dollars to 2 decimal places
            if (inputValue === "" || inputValue === "." || inputValue === "0.") {
              setInputValue("");
            } else if (parseFloat(inputValue) < 0.01) {
              setInputValue("");
            } else {
              setInputValue(parseFloat(inputValue).toFixed(2));
            }
          } else {
            // For shares, format to 6 decimal places
            if (inputValue === "" || inputValue === "." || inputValue === "0.") {
              setInputValue("");
            } else if (parseFloat(inputValue) < 0.000001) {
              setInputValue("");
            } else {
              setInputValue(parseFloat(inputValue).toFixed(6));
            }
          }
        }}
        className={`w-1/2 rounded-[8px] pl-4 ${className}`}
      />
    </>
  );
};

export default TradeWidgetInput;
