import { ethers } from "ethers";
import { offrampCoins } from "calls";
export async function sendFundsToCoinbase(client: any, partnerUserId: string): Promise<string> {
  try {
    // STEP 1: Fetch the latest Offramp transaction details from Coinbase
    const apiUrl = `https://api.developer.coinbase.com/onramp/v1/sell/user/${partnerUserId}/transactions`;
    console.log("Fetching Coinbase Offramp transaction details from:", apiUrl);

    const response = await offrampCoins(partnerUserId);

    if (!response.transactions) {
      throw new Error(`Error fetching transaction details: ${response}`);
    }

    const data = response;

    if (!data.transactions || data.transactions.length === 0) {
      throw new Error("No Offramp transaction found.");
    }

    const transactionDetails = data.transactions[0];
    console.log("Offramp transaction details:", transactionDetails);
    // Extract required fields from the transaction details.
    const { sell_amount, to_address } = transactionDetails;

    // STEP 2: Create the onchain transaction to send ETH from your app to Coinbase.
    console.log("value in wei", sell_amount);
    const valueInWei = BigInt(Math.floor(parseFloat(sell_amount.value) * 10 ** 18));

    const call = {
      to: to_address,
      value: valueInWei
    };

    const txParams = {
      account: client?.account,
      calls: [call]
    };

    const txHash = await client.sendTransaction(txParams);
    console.log("Onchain transaction sent with hash:", txHash);
    return txHash;
  } catch (error) {
    console.error("Error sending funds to Coinbase:", error);
    throw error;
  }
}
