import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const PlaceholderGraph: React.FC = () => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous drawings

    const width = 400;
    const height = 200;
    const margin = { top: 10, right: 10, bottom: 30, left: 30 };

    // Generate smoother and more gradual dummy data
    const data = d3.range(50).map((d) => ({
      x: d,
      y: 100 + d * 0.5 + 10 * Math.sin(d / 10) + Math.random() * 5 // Smooth upward trend with gentle fluctuations
    }));

    // Scales
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.x) as number])
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.y) as number])
      .range([height - margin.bottom, margin.top]);

    // Line generator
    const lineGenerator = d3
      .line<{ x: number; y: number }>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveMonotoneX); // Smooth curve

    // Gradient for area fill
    const defs = svg.append("defs");

    const gradient = defs
      .append("linearGradient")
      .attr("id", "areaGradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%");

    gradient.append("stop").attr("offset", "0%").attr("stop-color", "rgba(147, 134, 244, 0.3)");
    gradient.append("stop").attr("offset", "100%").attr("stop-color", "rgba(147, 134, 244, 0)");

    // Area generator
    const areaGenerator = d3
      .area<{ x: number; y: number }>()
      .x((d) => xScale(d.x))
      .y0(yScale(0))
      .y1((d) => yScale(d.y))
      .curve(d3.curveMonotoneX);

    // Add the shaded area
    svg.append("path").datum(data).attr("fill", "url(#areaGradient)").attr("d", areaGenerator);

    // Add the line
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "rgba(147, 134, 244, 0.8)")
      .attr("stroke-width", 2)
      .attr("d", lineGenerator);

    // Add text overlay
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height / 2)
      .attr("text-anchor", "middle")
      .attr("fill", "rgba(0, 0, 0, 0.5)")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("Not Enough Data To Draw Graph");
  }, []);

  return <svg ref={svgRef} width="100%" height="200" className="grayscale opacity-70" viewBox="0 0 400 200" />;
};

export default PlaceholderGraph;
