import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

// Define some basic custom styles for the modal
const customStyles: ReactModal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "8px",
    width: "400px",
    textAlign: "center",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface WithdrawConfirmationProps {
  setTransactionSuccess: (value: boolean) => void;
  isAnimating: boolean;
  withdrawAmount: string;
  outputAddress: string;
}

const WithdrawConfirmation: React.FC<WithdrawConfirmationProps> = ({
  setTransactionSuccess,
  isAnimating,
  withdrawAmount,
  outputAddress,
}) => {
  const [styles, setStyles] = useState<ReactModal.Styles>(customStyles);

  useEffect(() => {
    if (isAnimating) {
      // Example animation: you can adjust as needed
      setStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-50%, -50%) scale(1)",
          opacity: 1,
        },
      }));
    }
  }, [isAnimating]);

  const handleClose = () => {
    setTransactionSuccess(false);
  };

  return (
    <ReactModal isOpen={true} style={styles} ariaHideApp={false}>
      <div>
        <h2 className="text-2xl font-semibold mb-4">Withdraw Successful</h2>
        <p className="mb-4">
          Your withdraw of <strong>{withdrawAmount} USDC</strong> has been sent to{" "}
          <strong>{outputAddress}</strong>.
        </p>
        <button
          onClick={handleClose}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Close
        </button>
      </div>
    </ReactModal>
  );
};

export default WithdrawConfirmation;
