import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { sendFundsToCoinbase } from "./coinbaseOfframp";
import PrinxContext from "prinxDataContext";

interface OfframpConfirmationProps {
  client: any;
}

const OfframpConfirmation: React.FC<OfframpConfirmationProps> = ({ client }) => {
  const [status, setStatus] = useState("Processing Offramp Transaction...");
  const [searchParams] = useSearchParams();
  const { prinxUser, setPrinxUser } = useContext(PrinxContext);

  useEffect(() => {
    // Optionally, extract any needed info from the URL's query parameters.
    // For example, you might pass back partnerUserId or a session token.
    const partnerUserId = searchParams.get("partnerUserId") || "userId";

    async function handleOfframp() {
      if (prinxUser && client) {
        try {
          // Call our function that fetches transaction details and sends onchain funds.
          const txHash = await sendFundsToCoinbase(client, prinxUser._id);
          setStatus(`Transaction sent successfully. Hash: ${txHash}`);
        } catch (error: any) {
          setStatus(`Error: ${error.message}`);
        }
      }
    }
    handleOfframp();
  }, [client, searchParams, prinxUser]);

  return <div className="p-4 text-center">{status}</div>;
};

export default OfframpConfirmation;
