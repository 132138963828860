import React, { FC, useState } from "react";

interface DenominationSelectorProps {
  denomination: string;
  setDenomination: (value: string) => void;
}

const DenominationSelector: FC<DenominationSelectorProps> = ({ denomination, setDenomination }) => {
  return (
    <div className="flex flex-row w-1/2 p-1 items-center text-center rounded-[8px]" style={{ backgroundColor: "#D0DBF9" }}>
      <div
        onClick={() => {
          setDenomination("shares");
        }}
        style={{ background: denomination === "shares" ? "white" : "#D0DBF9" }}
        className={`w-1/2 h-full flex justify-center items-center text-xs leading-relaxed rounded-l-[8px] ${denomination === "shares" ? "font-bold" : "text-gray-600"}`}
      >
        Shares
      </div>
      <div
        onClick={() => {
          setDenomination("dollars");
        }}
        style={{ background: denomination === "dollars" ? "white" : "#D0DBF9" }}
        className={`w-1/2 h-full flex justify-center items-center text-xs leading-relaxed rounded-r-[8px] ${denomination === "dollars" ? "font-bold" : "text-gray-600"}`}
      >
        Dollars
      </div>
    </div>
  );
};
export default DenominationSelector;
