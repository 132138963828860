import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      waitlist: {
        investment_options: "",
        coming_2025: "Coming Spring 2025",
        ABTest_A:
          "Get yourself a small piece of something big. We are Prinx, an investment exchange where you can get access to shares of pre-ipo companies, at no minimum",
        ABTest_B:
          "Prinx is an exchange where you can get access to shares of pre-ipo companies, at no minimum. Get yourself a small piece of something big.",
        invest_in: "I'd like to invest in...",
        early_access: "Get Early Access",
        email: "Enter your email",
        otherText: ``
      },
      tos: "Terms of Service",
      pp: "Private Policy"
    }
  },
  ar: {
    translation: {
      waitlist: {
        investment_options: "خيارات الاستثمار",
        coming_2025: "قادمة في عام ٢٠٢٥",
        ABTest_A:
          "احصل على قطعة صغيرة من شيء كبير. نحن شركة برينكس، بورصة استثمارية حيث يمكنك الوصول إلى أسهم الشركات قبل الطرح العام الأولي، بدون حد أدنى.",
        ABTest_B:
          "برينكس هي بورصة يمكنك من خلالها الوصول إلى أسهم الشركات قبل الطرح العام الأولي، بدون حد أدنى. احصل على قطعة صغيرة من شيء كبير.",
        invest_in: "أود الاستثمار في",
        early_access: "احصل على وصول مبكر",
        email: "البريد الإلكتروني",
        otherText:
          "برينكس هي بورصة استثمارية مبتكرة، من المقرر إطلاقها في أوائل عام ٢٠٢٥. في الوقت الحالي، لا يتم تقديم أي أوراق مالية حيث لا نزال في مرحلة البحث. نود أن نسمع منك - أخبرنا ما هي فرص الاستثمار التي تهمك أكثر!"
      },
      tos: "شروط الخدمة",
      pp: "سياسة الخصوصية"
    }
  }
  //   fr: {
  //     translation: {
  //       waitlist: {
  //         "Investment Options:": "Options d'investissement:"
  //       }
  //     }
  //   },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
