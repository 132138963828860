import React, { FC, useContext, useEffect, useState } from "react";
import { CompanyDataType } from "constants/constants";
import PortfolioChart from "./PortfolioChart";
import PortfolioChartEmpty from "./PortfolioChartEmpty";
import BlockChainContext from "blockchainContext";
import SharePriceContext from "sharePriceContext";
import { formatToDollar } from "common";
import { getPortfolioData } from "../../calls/index";
import LoadingChartSkeleton from "components/common/Loaders/LoadingChartSkeleton";
import LoadingOneLineSkeleton from "components/common/Loaders/LoadingOneLineSkeleton";

const stockData = [{ date: new Date(), price: 0 }];
function determineValue(balances: { [key: string]: string }, companyData: CompanyDataType[]) {
  const aggregatedPrice = Object.entries(balances).reduce((total, [key, value]) => {
    const company = companyData.find((c: CompanyDataType) => c.title === key); // Find the matching company by title
    if (company) {
      const multiplier = parseFloat(value as string); // Convert value to a number
      const sharePrice = company.sharePrice; // Get sharePrice from CompanyData
      return total + multiplier * sharePrice; // Add the product to the total
    }
    return total; // If no match is found, return the total unchanged
  }, 0);
  return `${formatToDollar(aggregatedPrice.toString())}`;
  //return values.reduce((accumulator: any, item: any) => accumulator + item.sharePrice, 0);
}
const PortfolioSummary: FC = () => {
  const [portfolioSnapshots, setPortfolioSnapshots] = useState<any[] | null>();
  const { smartWalletAddress } = useContext(BlockChainContext);
  const { companyBalances, companyBalancesLoaded } = useContext(BlockChainContext);
  const { companyData, companyDataLoaded } = useContext(SharePriceContext);
  const [loadingData, setLoadingData] = useState(true);
  const value = determineValue(companyBalances, companyData);

  // console.log("Company Data", companyData);
  // console.log("Company Balances", companyBalances);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const stuff = await getPortfolioData(smartWalletAddress);
        setPortfolioSnapshots(stuff);
        setLoadingData(false);
        console.log("Snappies", portfolioSnapshots);
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
      }
    };
    if (smartWalletAddress) {
      fetchData();
    }
  }, [smartWalletAddress]);

  return (
    <div className="bg-white flex-col h-full w-full">
      <div className="p-4">
        <div className="text-m"> Portfolio Value </div>
        <div className="text-2xl mt-4">
          {!loadingData && companyDataLoaded && companyBalancesLoaded ? value : <LoadingOneLineSkeleton height="h-8" />}
        </div>
        {/* <div className="flex flex-row mt-2">
          <div className="text-green-700"> +$102(0.9%)</div>
          <div className="text-black pl-2"> Today </div>
        </div> */}
      </div>
      {loadingData ? (
        <LoadingChartSkeleton />
      ) : portfolioSnapshots && portfolioSnapshots.length ? (
        <PortfolioChart data={portfolioSnapshots} />
      ) : (
        <PortfolioChartEmpty />
      )}
    </div>
  );
};
export default PortfolioSummary;
