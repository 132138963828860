import { useState } from "react";
import { FaCopy } from "react-icons/fa";

const CopyWithTooltip = ({ textToCopy }: { textToCopy: string }) => {
  const [tooltip, setTooltip] = useState<{ visible: boolean; x: number; y: number }>({
    visible: false,
    x: 0,
    y: 0
  });

  const handleCopy = (e: React.MouseEvent) => {
    navigator.clipboard.writeText(textToCopy); // ✅ Copy text to clipboard

    // ✅ Show tooltip near cursor
    setTooltip({
      visible: true,
      x: window.innerWidth > 768 ? e.clientX - 500 : e.clientX - 100, // ✅ Absolute position (works better in some browsers)
      y: e.clientY - 100
    });
    // ✅ Hide tooltip after 2 seconds
    // setTimeout(() => {
    //   setTooltip({ visible: false, x: 0, y: 0 });
    // }, 2000);
  };

  return (
    <>
      <FaCopy onClick={handleCopy} className="cursor-pointer text-gray-500 hover:text-gray-700" />

      {tooltip.visible && (
        <div
          className="fixed bg-black text-white text-xs px-2 py-1 rounded border"
          style={{
            top: tooltip.y,
            left: tooltip.x,
            zIndex: 99999,
            pointerEvents: "none" // Ensures it doesn't block clicks
          }}
        >
          Copied!
        </div>
      )}
    </>
  );
};

export default CopyWithTooltip;
