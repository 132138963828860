import React, { useState, useEffect } from "react";
import sendWithdraw from "./withdrawFunction";

interface ManualWithdrawProps {
  client: any;
  ethersProvider: any;
  usdcBalance: string;
  onWithdrawSuccess: (data: { withdrawAmount: string; outputAddress: string }) => void;
  setIsLoading?: (loading: boolean) => void;
}

const ManualWithdraw: React.FC<ManualWithdrawProps> = ({
  client,
  ethersProvider,
  usdcBalance,
  onWithdrawSuccess,
  setIsLoading,
}) => {

  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [isWalletValid, setIsWalletValid] = useState<boolean>(false);
  const [isAmountValid, setIsAmountValid] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  // Update isButtonDisabled when either input validity changes.
  useEffect(() => {
    setIsButtonDisabled(!(isWalletValid && isAmountValid));
  }, [isWalletValid, isAmountValid]);

  const validateWalletAddress = (address: string): boolean => {
    const walletRegex = /^0x[a-fA-F0-9]{40}$/;
    return !!address && walletRegex.test(address);
  };

  const handleAddressChange = (value: string) => {
    setWithdrawAddress(value);
    if (validateWalletAddress(value)) {
      setIsWalletValid(true);
    } else {
      setIsWalletValid(false);
    }
  };

  const handleAmountChange = (value: string) => {
    setWithdrawAmount(value);
    const amount = parseFloat(value);
    if (isNaN(amount)) {
      setIsAmountValid(false);
      return;
    }

    // Ensure there are no more than 6 digits after the decimal point.
    const parts = value.split(".");
    if (parts.length > 1 && parts[1].length > 6) {
      setIsAmountValid(false);
      return;
    }

    // Valid if amount is a number, at least 1, and not more than the USDC balance.
    if (amount >= 1 && amount <= Number(usdcBalance)) {
      setIsAmountValid(true);
    } else {
      setIsAmountValid(false);
    }
  };

  const handleWithdraw = async () => {
    try {
      if (setIsLoading) setIsLoading(true);
      const result = await sendWithdraw({
        client,
        ethersProvider,
        setIsLoading: (loading: boolean) => {
          if (setIsLoading) setIsLoading(loading);
        },
        outputWalletAddress: withdrawAddress,
        withdrawAmount: withdrawAmount,
      });
      if (setIsLoading) setIsLoading(false);
      if (result.txHash !== "") {
        onWithdrawSuccess({ withdrawAmount: result.withdrawAmount, outputAddress: result.toAddress });
      }
    } catch (error) {
      if (setIsLoading) setIsLoading(false);
      console.error("Withdraw error:", error);
    }
  };

  return (
    <div className="mt-4 w-full">
      <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded-md mt-12">
        Make sure that the address is valid on Base.
      </div>
      <div className="mt-4">
        <input
          type="text"
          placeholder="Enter wallet address"
          value={withdrawAddress}
          onChange={(e) => handleAddressChange(e.target.value)}
          className="p-2 border border-gray-300 rounded w-full"
        />
        <input
          type="number"
          placeholder="Enter USDC withdraw amount"
          value={withdrawAmount}
          onChange={(e) => handleAmountChange(e.target.value)}
          className={`p-2 rounded w-full mt-4 border ${
            withdrawAmount !== "" && Number(withdrawAmount) !== 0 && !isAmountValid
              ? "border-red-500 text-red-600"
              : "border-gray-300 text-gray-700"
          } placeholder:text-gray-400`}
        />
        {isButtonDisabled && (
          <div>
            {withdrawAddress && !validateWalletAddress(withdrawAddress) && (
              <p className="mt-4 text-sm text-red-500">Not a Base Wallet Address</p>
            )}
            {withdrawAmount && Number(withdrawAmount) !== 0 && (
              <p className="mt-4 text-sm text-red-500">
                {withdrawAmount.includes(".") && withdrawAmount.split(".")[1].length > 6
                  ? "Error: USDC does not go past 6 decimal places"
                  : parseFloat(withdrawAmount) < 1
                  ? "Insignificant value: Your withdraw must be at least $1"
                  : parseFloat(withdrawAmount) > Number(usdcBalance)
                  ? "Insufficient Balance: You do not have enough USDC to withdraw this amount"
                  : ""}
              </p>
            )}
          </div>
        )}
        <p className="mt-4 text-sm text-gray-700">Your USDC Balance: {usdcBalance}</p>
        <button
          className={`rounded p-2 mt-4 text-white ${
            isButtonDisabled ? "bg-blue-300 opacity-50 cursor-not-allowed" : "bg-blue-500"
          }`}
          disabled={isButtonDisabled}
          onClick={handleWithdraw}
        >
          Withdraw
        </button>
      </div>
    </div>
  );
};

export default ManualWithdraw; 