import { FC, useContext, useEffect, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import CustomSelect from "components/Trade/CustomSelect";
import { SharePriceContext } from "sharePriceContext";
import { CompanyDataType } from "constants/constants";
import { getUsersWithCoin } from "calls";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
const renderUserTable = (users: any) => {
  if (!users || users.length === 0) {
    return <div>No users available.</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-2 py-2 text-sm">Email</th>
            <th className="border border-gray-300 px-2 py-2 text-sm">Smart Wallet</th>
            <th className="border border-gray-300 px-2 py-2 text-sm">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: any, index: number) => (
            <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
              <td className="border border-gray-300 px-2 py-2 text-sm">
                <Link to={`/admin/users/${user._id}`} state={{ user }}>
                  {user.email}
                </Link>
              </td>
              <td className="border border-gray-300 px-2 py-2 text-sm">{user.smart_wallet}</td>
              <td className="border border-gray-300 px-2 py-2 text-sm">
                {ethers.utils.formatUnits(user.coinBalance, 6)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const UsersByToken: FC = () => {
  const { user } = usePrivy();
  const { companyData } = useContext(SharePriceContext);

  // Ensure selectedCompany is always initialized with a valid value
  const [selectedCompany, setSelectedCompany] = useState<CompanyDataType>(
    companyData?.[0] || { id: "default", title: "Select Company", address: "default-address" } // Fallback
  );
  const [users, setUsers] = useState();

  useEffect(() => {
    // Update selectedCompany only when companyData becomes available
    if (companyData && companyData.length > 0) {
      setSelectedCompany((current) => current || companyData[0]);
    }
  }, [companyData]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!selectedCompany) return; // Safety check, though it shouldn't be null
      try {
        const users = await getUsersWithCoin(selectedCompany.address);
        setUsers(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [selectedCompany]);

  //   if (!companyData || companyData.length === 0) {
  //     return <div>Loading...</div>; // Ensure we have data before rendering
  //   }

  return (
    <div
      style={{ backgroundColor: "rgb(245, 247, 253)", minHeight: "100vh" }}
      className="bg-white flex-col h-full w-full"
    >
      {/* Pass a guaranteed valid selectedCompany */}
      {!companyData || companyData.length === 0 ? <div> Loading Companies </div> : null}
      <CustomSelect items={companyData} setSelectedCompany={setSelectedCompany} selectedCompany={selectedCompany} />
      <div className="mt-8">
        <h2 className="text-lg font-bold mb-4">User Data</h2>
        {renderUserTable(users)}
      </div>
    </div>
  );
};

export default UsersByToken;
