import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

interface WheelProps {
  progress: number; // Progress (0-100)
  size?: number; // Wheel size (default: 150px)
  strokeWidth?: number; // Thickness of wheel
}

const GradientWheel: React.FC<WheelProps> = ({ progress, size = 150, strokeWidth = 12 }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;

    // Ensure progress stays within 0-100 range
    const clampedProgress = Math.max(0, Math.min(progress, 100));
    const progressOffset = circumference * (1 - clampedProgress / 100);

    const svg = d3.select(svgRef.current);

    // Clear existing content
    svg.selectAll("*").remove();

    // Define linear gradient for the stroke
    const defs = svg.append("defs");

    const linearGradient = defs
      .append("linearGradient")
      .attr("id", "wheelGradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");

    linearGradient.append("stop").attr("offset", "0%").attr("stop-color", "rgb(147, 134, 244)"); // Start Color
    linearGradient.append("stop").attr("offset", "100%").attr("stop-color", "rgb(67, 111, 230)"); // End Color

    // Draw the background circle
    svg
      .append("circle")
      .attr("cx", size / 2)
      .attr("cy", size / 2)
      .attr("r", radius)
      .attr("stroke", "#ddd")
      .attr("stroke-width", strokeWidth)
      .attr("fill", "none");

    // Draw the progress arc
    svg
      .append("circle")
      .attr("cx", size / 2)
      .attr("cy", size / 2)
      .attr("r", radius)
      .attr("stroke", "url(#wheelGradient)") // Apply gradient
      .attr("stroke-width", strokeWidth)
      .attr("fill", "none")
      .attr("stroke-linecap", "round")
      .attr("stroke-dasharray", circumference) // Full circumference
      .attr("stroke-dashoffset", progressOffset) // Adjust based on progress
      .attr("transform", `rotate(-90, ${size / 2}, ${size / 2})`); // Start from top
  }, [progress, size, strokeWidth]);

  return <svg ref={svgRef} width={size} height={size} />;
};

export default GradientWheel;
