import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const LoadingLineChart: React.FC = () => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous elements

    const width = 400;
    const height = 200;
    const padding = 20;
    const numPoints = 10;

    // Ensure upward movement by making each point higher than the last
    let lastY = height - padding; // Start near the bottom
    const loadingData = Array.from({ length: numPoints }, (_, i) => {
      lastY -= Math.random() * 10 + 5; // Ensuring the y-value always decreases (moving up)
      return { x: i * (width / numPoints), y: Math.max(lastY, padding) }; // Ensure it doesn't go too high
    });

    // Define scales
    const xScale = d3
      .scaleLinear()
      .domain([0, width])
      .range([padding, width - padding]);
    const yScale = d3
      .scaleLinear()
      .domain([height, 0])
      .range([height - padding, padding]);

    // Define line generator
    const line = d3
      .line<{ x: number; y: number }>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveMonotoneX); // Smooth curve

    // Append the path
    const path = svg
      .append("path")
      .datum(loadingData)
      .attr("fill", "none")
      .attr("stroke", "#cbd5e1") // Light gray
      .attr("stroke-width", 3)
      .attr("stroke-linecap", "round")
      .attr("d", line);

    // Animate the path with a "drawing effect" and loop with `.on("end", function() {...})`
    const totalLength = path.node()?.getTotalLength() || 0;
    function animatePath() {
      path
        .attr("stroke-dasharray", `${totalLength} ${totalLength}`)
        .attr("stroke-dashoffset", totalLength)
        .transition()
        .duration(1500)
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", "0")
        .on("end", animatePath); // ✅ Loop animation
    }

    animatePath(); // Start animation

    return () => {
      svg.selectAll("*").remove(); // Cleanup on unmount
    };
  }, []);

  return <svg ref={svgRef} width="100%" height="200" className="bg-white rounded-lg shadow-md"></svg>;
};

export default LoadingLineChart;
