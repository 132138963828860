import { FC, useContext, useEffect, useState } from "react";

import { useLogin, usePrivy } from "@privy-io/react-auth";
import InvestmentCard from "components/Home/InvestmentCard";
import { useSearchParams } from "react-router-dom";

import AssetTable from "./AssetTable";
import MarketTable from "./MarketTable";
import PendingTransactionsTable from "./PendingTransactionTable";

import PortfolioSummary from "./PortfolioSummary";
import BalanceSummary from "./BalanceSummary";
import BlockChainContext from "blockchainContext";

import { useWindowSize } from "hooks";
import SharePriceContext from "sharePriceContext";
import UserSteps from "components/UserSteps";

async function fund(func: any, addr: any) {
  await func(addr);
}

const Profile: FC = () => {
  const { authenticated, linkPhone, user } = usePrivy();
  const { usdcBalance, companyBalances, companyBalancesLoaded } = useContext(BlockChainContext);
  const { isSmallScreen, isMobile } = useWindowSize();
  const { companyData, companyDataLoaded, primaryCompanyData } = useContext(SharePriceContext);
  const [searchParams] = useSearchParams();
  const investment = primaryCompanyData[1];

  const { login } = useLogin({
    onComplete: (user) => {
      console.log("I never see this user 2", user);
      // if (process.env.NODE_ENV == "production")
      //navigate("/profile");
    },
    onError: (error) => {
      console.log("Privy onLogin Error", error);
    }
  });

  useEffect(() => {
    const phoneAccount = user?.linkedAccounts.find((account) => account.type === "phone");
    if (authenticated && !phoneAccount) {
      // linkPhone();
    }
  }, [authenticated]);

  useEffect(() => {
    console.log("User?", user);
    const signup = searchParams.get("signup"); // '123'
    if (signup && !authenticated && process.env.REACT_APP_ENV !== "ALPHA") login();
    if (!user && process.env.REACT_APP_ENV !== "ALPHA") login();
  }, [user]);
  return (
    <div style={{ width: "100%", backgroundColor: "#F5F7FD" }} className="p-8">
      <h1 className="text-4xl mb-10">
        {" "}
        Welcome Back
        {(user?.linkedAccounts[0] as any)?.name ? `, ${(user?.linkedAccounts[0] as any)?.name}` : ""}
      </h1>
      <div className="flex gap-4">
        {isSmallScreen ? (
          // Single Grid Layout for Small Screens
          <div className="w-full">
            <UserSteps usdcBalance={usdcBalance} />
            <div className="grid grid-cols-2 gap-4">
              <div className={`flex ${isMobile ? "col-span-2" : "col-span-1"}`}>
                <PortfolioSummary />
              </div>
              <div className={`flex ${isMobile ? "col-span-2" : "col-span-1"}`}>
                <BalanceSummary usdcBalance={usdcBalance} />
              </div>
              <div className="bg-white p-4 col-span-2">
                <div className="text-m mb-4"> Assets </div>
                <AssetTable
                  companyBalances={companyBalances}
                  companyData={companyData}
                  companyDataLoaded={companyDataLoaded}
                  companyBalancesLoaded={companyBalancesLoaded}
                />
              </div>
              <div className="bg-white mt-4 col-span-2">
                <div className="p-4 flex flex-col h-full">
                  <div className="text-m"> Pending Transactions </div>
                  <PendingTransactionsTable />
                </div>
              </div>
              {/* Contents from the Right Grid */}
              <div className="bg-white p-4 col-span-2">
                <div className="flex flex-col">
                  <div className="text-lg"> Featured in The Primary Market</div>
                  <div className="text-sm text-gray-400">
                    Fund the next generation of world-class startups, projects, and more
                  </div>
                  <InvestmentCard investment={investment} />
                </div>
              </div>
              <div className="bg-white p-4 col-span-2">
                <div className="text-xl"> Markets </div>
                <MarketTable companyData={companyData} />
              </div>
            </div>
          </div>
        ) : (
          // Two-Column Layout for Larger Screens
          <>
            {/* Left Grid Container (66% width) */}
            <div className="w-2/3">
              <UserSteps usdcBalance={usdcBalance} />

              <div className="grid grid-cols-2 gap-4">
                <PortfolioSummary />
                <BalanceSummary usdcBalance={usdcBalance} />
                <div className="bg-white p-4 col-span-2">
                  <div className="text-m mb-4"> Assets </div>
                  <AssetTable
                    companyBalances={companyBalances}
                    companyData={companyData}
                    companyDataLoaded={companyDataLoaded}
                    companyBalancesLoaded={companyBalancesLoaded}
                  />
                </div>
              </div>
              <div className="bg-white mt-4">
                <div className="p-4 flex flex-col h-full">
                  <div className="text-m"> Pending Transactions </div>
                  <PendingTransactionsTable />
                </div>
              </div>
            </div>
            {/* Right Grid Container (33% width) */}
            <div className="w-1/3">
              <div className="grid grid-rows-2 gap-4">
                <div className="bg-white">
                  <div className="p-4 flex flex-col">
                    <div className="text-lg"> Featured in The Primary Market</div>
                    <div className="text-sm text-gray-400">
                      Fund the next generation of world-class startups, projects, and more
                    </div>
                    <InvestmentCard investment={investment} />
                  </div>
                </div>
                <div className="bg-white p-4">
                  <div className="text-xl"> Markets </div>
                  <MarketTable companyData={companyData} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
