import React from "react";
import PortfolioImage from "../../assets/images/PortfolioPhoto.png";
import texture from "../../assets/images/Texture.png";
import { useWindowSize } from "../../hooks";
import CompanySlider from "./CompanySlider";
import { useNavigate } from "react-router-dom";
import Prinx from "../../assets/images/prinx.png";
import BackgroundShadow from "../../assets/BackgroundShadows/Shadow_1";

const Masthead: React.FC = () => {
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  const handleJoinWaitlist = () => {
    let append = "?signup=true";
    if (process.env.REACT_APP_ENV === "ALPHA") {
      append = "";
    }
    navigate(`/profile${append}`);
  };

  return (
    <section className="relative lg:pl-6 pb-16 overflow-hidden">
      <BackgroundShadow />
      <div className="ml-0 lg:ml-20 grid grid-cols-1 lg:grid-cols-2 items-center min-h-screen px-2 md:px-4 lg:pr-0 relative lg:-top-10">
        <div className="absolute top-4 left-4 lg:top-16 lg:left-8 flex items-center gap-2">
          <img src={Prinx} alt="Prinx Logo" className="w-20 lg:w-24 filter brightness-0 invert" />
        </div>

        {/* Right Login (Positioned on the top right) */}
        <div className="absolute top-2 right-2 lg:top-16 lg:right-8 flex items-center gap-2 cursor-pointer">
          <p onClick={() => navigate("/profile?signup=true")}>Login</p>
        </div>
        {/* Left Column: Text Content */}
        <div className="flex flex-col items-center lg:items-start justify-center text-center lg:text-left mb-12 p-2 pt-8">
          <div className="mb-4 mt-4">
            <div
              style={{
                background: "linear-gradient(90deg, #9386F4, #436FE6)",
                padding: "1px",
                borderRadius: "20px",
                display: "inline-block",
                margin: "0 auto"
              }}
            >
              <div
                style={{
                  background: "#131C34",
                  borderRadius: "20px",
                  padding: "4px 16px",
                  fontSize: "18px"
                }}
              >
                Invest like the 1%
              </div>
            </div>
          </div>
          <h1 className="pt-3 text-5xl sm:text-xl lg:text-7xl mb-6 tracking-widest px-0 md:px-0">
            Invest & trade
            <br />
            Pre-IPO shares
          </h1>
          <p className="text-white text-xl pt-2 mb-8 max-w-xl pr-0 md:pr-8 pb-2 text-center md:text-left">
            <b>Secure & easy </b> access to <b>private</b> investment opportunities so you can <b>diversify</b> your
            portfolio.
          </p>
          <button
            onClick={handleJoinWaitlist}
            style={{ background: "#436FE7", position: "relative", top: isMobile ? 20 : 0 }}
            className="text-white px-6 py-3 rounded text-lg hover:bg-blue-700 transition relative sm:-top-10"
          >
            Sign Up
          </button>
        </div>
        {/* Right Column: Image */}
        <div className="relative lg:h-[530px] flex items-center justify-center mb-48 lg:mb-0 pt-6 md:pt-0">
          <img
            style={{
              boxShadow: "0 0 15px 1px rgba(67, 111, 230, 0.8)"
            }}
            src={PortfolioImage}
            alt="Dashboard Placeholder"
            className="transform rounded-xl shadow-lg border-2 border-[#436FE6] lg:rounded-none lg:rounded-tl-2xl lg:rounded-bl-2xl lg:border-r-0 lg:h-full lg:w-auto lg:object-cover lg:object-left lg:translate-x-24"
          />
        </div>
        {/* Bottom Section */}
      </div>
      <div className="absolute bottom-0 left-0 w-full text-white text-center h-44 md:h-auto">
        <div className="relative h-full">
          {/* Background Texture */}
          <div
            style={{
              background: `url('${texture}') repeat`,
              backgroundSize: "50%",
              zIndex: 9
            }}
            className="absolute inset-0"
          ></div>
          {/* Gradient Overlay */}
          <div
            style={{
              background: "linear-gradient(to right, rgba(147, 134, 244, 0.8) 0%, rgba(67, 111, 230, 0.8) 100%)"
            }}
            className="absolute inset-0"
          ></div>
          {/* Content Wrapper */}
          <div className="h-full flex flex-col justify-center">
            {/* Content */}
            <div className="grid grid-cols-1 md:grid-cols-3 items-center h-auto md:h-[120px] z-10">
              {/* Text Content */}
              <div
                style={{ height: "100%", zIndex: 10 }}
                className="md:col-span-1 flex flex-col justify-center items-center md:items-start pt-4 md:pt-0 px-12 md:px-8 mb-4 md:mb-0"
              >
                <h2 className="tracking-widest text-xl lg:text-2xl font-bold text-white text-center md:text-left">
                  Invest in private companies with as little as $1.
                </h2>
              </div>

              {/* CompanySlider */}
              <div className="md:col-span-2">
                <CompanySlider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Masthead;
