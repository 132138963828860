import React, { FC, useState } from "react";

interface ActionSelectorProps {
  action: string;
  setAction: (value: string) => void;
}

const ActionSelector: FC<ActionSelectorProps> = ({ action, setAction }) => {
  return (
    <div style={{ backgroundColor: "#F3F3F3", height: '43px' }} className="flex flex-row p-1 mb-2 rounded-[8px]">
      <div
        onClick={() => setAction("buy")}
        className={`flex-1 h-full flex items-center justify-center rounded-l-[8px] ${action === "buy" ? "bg-white font-bold" : "text-gray-600"}`}
      >
        Buy
      </div>
      <div
        onClick={() => setAction("sell")}
        className={`flex-1 h-full flex items-center justify-center rounded-r-[8px] ${action === "sell" ? "bg-white font-bold" : "text-gray-600"}`}
      >
        Sell
      </div>
    </div>
  );
};
export default ActionSelector;
