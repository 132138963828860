import ErrorOutline from "../../assets/images/error-outline.png";
import { CompanyDataType } from "constants/constants";
import { formatToDollar } from "common";
import { PrimaryInvestmentType } from "types";
import React from "react";

interface InsufficientFundsProps {
  usdcBalance: string;
}
const InsufficientFunds: React.FC<InsufficientFundsProps> = ({ usdcBalance }) => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
      <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Insufficient Funds</div>
      <div className="text-xs">
        You currently have {formatToDollar(usdcBalance)} Edit your order or deposit more funds in order to make your
        purchase
      </div>
      <div className="text-xs text-blue-600"> Make a Deposit </div>
    </div>
  );
};

interface InsufficientSharesProps {
  companyBalances: { [key: string]: string }; // Adjust type if needed
  selectedCompany: CompanyDataType; // Adjust type if needed
}
const InsufficientShares: React.FC<InsufficientSharesProps> = ({ companyBalances, selectedCompany }) => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
      <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Not Enough Shares to Sell</div>
      <div className="text-xs"> You currently have {companyBalances[selectedCompany.title]} shares </div>
    </div>
  );
};

interface InsufficientSharesAvailableProps {
  poolBalance: string;
  sharesAmount: string;
  idx: number;
  denomination: string;
  inputValue: string;
}
const InsufficientSharesAvailable: React.FC<InsufficientSharesAvailableProps> = ({ poolBalance, sharesAmount, idx, denomination, inputValue }) => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
      <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Insufficient Shares Available</div>
      <div className="text-xs">
        {denomination === "dollars" 
          ? `You are trying to buy ${sharesAmount[idx]} shares but only ${poolBalance} shares are available`
          : `You are trying to buy ${inputValue} shares but only ${poolBalance} shares are available`}
      </div>
    </div>
  );
};

interface InsufficientLiquidityProps {
  poolUsdcBalance: string;
  usdcAmount: string;
  idx: number;
  denomination: string;
  inputValue: string;
}
const InsufficientLiquidity: React.FC<InsufficientLiquidityProps> = ({ poolUsdcBalance, usdcAmount, idx, denomination, inputValue }) => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
      <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Insufficient Liquidity Available</div>
      <div className="text-xs">
        {denomination === "shares" 
          ? `You are trying to sell $${Number(usdcAmount[idx]).toFixed(2)} worth of shares but only $${Number(poolUsdcBalance).toFixed(2)} is available in the liquidity pool`
          : `You are trying to sell $${Number(inputValue).toFixed(2)} worth of shares but only $${Number(poolUsdcBalance).toFixed(2)} is available in the liquidity pool`}
      </div>
    </div>
  );
};






interface OverMaxAllocationProps {
  goal: string; // Adjust type if needed
  amountIn: string; // Adjust type if needed
}
const OverMaxAllocation: React.FC<OverMaxAllocationProps> = ({ goal, amountIn }) => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
      <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Not Enought Allocation Space Left</div>
      <div className="text-xs">
        {" "}
        You are trying to buy ${amountIn} worth of shares, but there is only ${goal} of space left{" "}
      </div>
    </div>
  );
};


interface InsufficientValueProps{
}
const InsufficientValue: React.FC<InsufficientValueProps> = () => {
  return (
    <div className="relative px-6 py-4 border rounded mt-4">
     <img src={ErrorOutline} style={{ position: "absolute", top: 18, left: 6, width: 12, height: 12 }} />
      <div className="text-xs font-bold"> Insufficient Value</div>
      <div className="text-xs">
        {" "}
        You are trying to make a transaction that is under $1 in value. Please edit your transation.
      </div>
    </div>
  );
};



interface ErrorMessageProps {
  isButtonDisabled: boolean;
  action: string;
  denomination: string;
  inputValue: any;
  usdcBalance: any;
  selectedCompany: any;
  usdcAmount: any;
  idx: number;
  sharesAmount: any;
  companyBalances: any;
  poolUsdcBalance: any;
  poolBalance: any;
}

const ErrorMessages: React.FC<ErrorMessageProps> = ({
  isButtonDisabled,
  action,
  denomination,
  inputValue,
  usdcBalance,
  usdcAmount,
  idx,
  selectedCompany,
  sharesAmount,
  companyBalances,
  poolUsdcBalance,
  poolBalance
}) => {
  if (
    isButtonDisabled &&
    action === "buy" &&
    ((denomination === "dollars" && Number(inputValue) > Number(usdcBalance)) ||
      (denomination === "shares" && Number(usdcAmount[idx]) > Number(usdcBalance)))
  ) {
    return <InsufficientFunds usdcBalance={usdcBalance} />;
  } else if (
    isButtonDisabled &&
    action === "buy" &&
    ((denomination === "dollars" && Number(sharesAmount[idx]) > Number(poolBalance)) || // Check if sharesAmount is greater than poolBalance
    (denomination === "shares" && Number(inputValue) > Number(poolBalance)))
  ) {
    return <InsufficientSharesAvailable poolBalance={poolBalance} sharesAmount={sharesAmount} idx={idx} denomination={denomination} inputValue={inputValue} />;
  } else if (
    isButtonDisabled &&
    action === "sell" &&
    ((denomination === "shares" && Number(inputValue) > Number(companyBalances[selectedCompany.title])) ||
      (denomination === "dollars" && Number(sharesAmount[idx]) > Number(companyBalances[selectedCompany.title])))
  ) {
    return <InsufficientShares selectedCompany={selectedCompany} companyBalances={companyBalances} />;
  } else if (
      isButtonDisabled &&
      action === "sell" &&
      ((denomination === "shares" && Number(usdcAmount[idx]) > Number(poolUsdcBalance)) || // Check if sharesAmount is greater than poolBalance
      (denomination === "dollars" && Number(inputValue) > Number(poolUsdcBalance)))
    ) {
      return <InsufficientLiquidity poolUsdcBalance={poolUsdcBalance} usdcAmount={usdcAmount} idx={idx} denomination={denomination} inputValue={inputValue} />;

  } else if (
    isButtonDisabled &&
    (
      (denomination === "dollars" && inputValue && Number(inputValue) < 1) ||  
      (denomination === "shares" && Number(usdcAmount[idx]) < 1)
    )
  ) {
    return <InsufficientValue />;
  } else {
    return null;
  }
};

export { InsufficientFunds, InsufficientShares, OverMaxAllocation, ErrorMessages, InsufficientValue, InsufficientSharesAvailable, InsufficientLiquidity};
