// USDC address
export const USDC_ADDRESS = "0x009764F6D17337B6a0033d95A30bDc25896075b4";

// tokens addresses
export const SPACEX_ADDRESS = "0x626fF50B8d6Bd2cb16178621F3FbE2416b9A3147";
export const DATA_BRICKS_ADDRESS = "0x1fE2f3a8BbAdFF8b73A1229c63204CDC709c2eB8";
export const UNITY_ADDRESS = "0x0f6B2AadEe012EE3Cc71091f224b6eA614602b57";

// deposit contracts
export const SpaceXDepositContract = "0xCD1fCe8679588A7Fc7BdcE72bcAb47563517C85b";
export const EpicGamesDepositContract = "0x280756cc4B3fb390A9DAE0C1741cD1e36BCc94B3";
export const UnityDepositContract = "0x81f784D435D252D7CDC6B3B95518c1d16D349De5";


export const SpaceXTradePool = "0x4C8Db813184b80d20BC32Baf1575a21fe3E345CB";
export const DataBricksTradePool = "0xFeE88c0De41e1E55826344d0A5DA5d7cb3dD35cf";
export const UnityTradePool = "0xB4Ace858f8bc172a83bA55e49b3D169e5aa1d065";
