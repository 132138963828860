import {
  errorIndicatesMfaVerificationFailed,
  errorIndicatesMfaTimeout,
  errorIndicatesMaxMfaRetries,
  useMfaEnrollment,
  usePrivy
} from "@privy-io/react-auth";
import QRCodeModal from "components/Modals/QRCodeModal";
import React, { useEffect, useState } from "react";
import QRCode from "qrcode";

interface SliderToggleProps {}
const SliderToggle: React.FC<SliderToggleProps> = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const { initEnrollmentWithTotp, submitEnrollmentWithTotp, unenrollWithTotp } = useMfaEnrollment();
  const [qrCode, setQrCode] = useState("");
  const [code, setCode] = useState("");
  const { user } = usePrivy();

  console.log("User In Slider", user);
  useEffect(() => {
    if (user) {
      if (user.mfaMethods.includes("totp")) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }
  }, [user]);
  const generateQRCode = async (url: string) => {
    try {
      const qr = await QRCode.toDataURL(url);
      setQrCode(qr);
    } catch (error) {
      console.error("Error generating QR Code:", error);
    }
  };

  const onEnable = async () => {
    console.log("Multifactor authentication enabled!");
    const { authUrl, secret } = await initEnrollmentWithTotp();
    console.log("Auth Url?", authUrl);
    if (authUrl) {
      generateQRCode(authUrl);
      setIsQRModalOpen(true);
    } else {
      console.warn("Failed to create auth url");
    }
  };

  const handleToggle = () => {
    if (isToggled) {
      onDisable();
    } else {
      onEnable();
    }
    setIsToggled(!isToggled);
  };
  const onDisable = async () => {
    try {
      await unenrollWithTotp();
      console.log("Multifactor authentication disabled!");
    } catch (e) {
      setIsToggled(true);
    }
  };
  const handleMFAConfirmEnable = async () => {
    const mfaCodeInput = code; // Prompt the user for the code in their TOTP app
    try {
      // Errors from `submitEnrollmentWithSms` and `submitEnrollmentWithTotp` can be handled similarly
      await submitEnrollmentWithTotp({ mfaCode: mfaCodeInput });
      console.log("did it work");
      setIsQRModalOpen(false);
    } catch (e) {
      setIsToggled(false);
      if (errorIndicatesMfaVerificationFailed(e)) {
        console.error("Incorrect MFA code, please try again.");
        // Allow the user to re-enter the code and call `submit` again
      } else if (errorIndicatesMaxMfaRetries(e)) {
        console.error("Maximum MFA attempts reached, please request a new code.");
        // Allow the user to request a new code with `init`
      } else if (errorIndicatesMfaTimeout(e)) {
        console.error("MFA code has expired, please request a new code.");
        // Allow the user to request a new code with `init`
      }
    }
  };

  return (
    <div className="flex items-center justify-between w-full">
      <label className="text-gray-700 font-medium">
        Enable multifactor authentication for transactions (recommended)
      </label>
      <div
        className={`relative inline-block w-12 h-6 ${
          isToggled ? "bg-green-500" : "bg-gray-300"
        } rounded-full cursor-pointer transition`}
        onClick={handleToggle}
      >
        <span
          className={`absolute top-1 left-1 h-4 w-4 bg-white rounded-full shadow-md transform transition ${
            isToggled ? "translate-x-6" : "translate-x-0"
          }`}
        ></span>
      </div>
      {qrCode ? (
        <QRCodeModal
          isOpen={isQRModalOpen}
          onClose={() => {
            setIsQRModalOpen(false);
          }}
          qrCode={qrCode}
          onConfirm={handleMFAConfirmEnable}
          setCode={setCode}
        />
      ) : null}
    </div>
  );
};
export default SliderToggle;

// Usage Example
