import { ethers } from "ethers";
import { recordTransaction } from "calls";
import swapRouterabi from "../../contracts/swapRouterabi.json";
const swapRouterAddress = "0x2626664c2603336E57B271c5C0b26F421741e481";
import { encodeFunctionData } from "viem";
import { estimateUserOperationGas } from "../../utils/gasEstimation";
import { BUY_FEE_PERCENTAGE, SELL_FEE_PERCENTAGE, FEE_RECIPIENT_ADDRESS } from "../../constants/feeConfig";
import { TransactionReceipt, User } from "@privy-io/react-auth";
import { ABI } from "../../types/index";
//some type conversion for typescript
type NormalizedReceipt = Omit<TransactionReceipt, "gasUsed"> & {
  gasUsed: string;
  cumulativeGasUsed: string;
  effectiveGasPrice: string;
};

//setting the max apporval amount
const MAX_APPROVAL = BigInt("999999999999999999"); // Large approval amount

const abiCoder = new ethers.utils.AbiCoder();
//allowing any for next line for now
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const encodeBatchCalls = (calls: any[]) => {
  return abiCoder.encode(["tuple(address to, bytes data)[]"], [calls]);
};

// SWAP
export async function executeSwap(
  user: User,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any,
  smartWalletAddress: string,
  ethersProvider: ethers.providers.Web3Provider,
  amountIn: string,
  tokenIn: string,
  abi: ABI,
  tokenOut: string,
  action: string,
  ticker: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setReceipt: React.Dispatch<React.SetStateAction<any>>
): Promise<void> {
  if (!client || !amountIn || isNaN(Number(amountIn)) || Number(amountIn) <= 0) {
    console.error("Invalid input amount for swap");
    return;
  }

  try {
    // set that amount to the amount in big number
    const amountInBigNumber = ethers.utils.parseUnits(amountIn.toString(), 6);

    // calculates the amounts with fee
    const feePercentage = action === "buy" ? BUY_FEE_PERCENTAGE : SELL_FEE_PERCENTAGE;
    const feeAmount = amountInBigNumber.mul(feePercentage * 100).div(100);
    const amountAfterFee = amountInBigNumber.sub(feeAmount);

    // params for the user swap
    const paramsUser = {
      tokenIn: tokenIn,
      tokenOut: tokenOut,
      fee: 3000, // Assuming pool fee is 0.3%
      recipient: smartWalletAddress,
      amountIn: amountAfterFee,
      amountOutMinimum: 0,
      sqrtPriceLimitX96: 0
    };

    // Create provider only once and use the one passed in as parameter
    const tokenContract = new ethers.Contract(tokenIn, abi, ethersProvider);
    const currentAllowance = await tokenContract.allowance(smartWalletAddress, swapRouterAddress);

    // Define the calls based on allowance
    let calls;
    if (currentAllowance.lt(ethers.BigNumber.from("999999999999"))) {
      // If allowance is not set, include approve call
      calls = [
        {
          to: tokenIn,
          data: encodeFunctionData({
            abi: abi,
            functionName: "approve",
            args: [swapRouterAddress, MAX_APPROVAL]
          })
        },
        {
          to: swapRouterAddress,
          data: encodeFunctionData({
            abi: swapRouterabi,
            functionName: "exactInputSingle",
            args: [paramsUser]
          })
        },
        {
          to: tokenIn,
          data: encodeFunctionData({
            abi: abi,
            functionName: "transfer",
            args: [FEE_RECIPIENT_ADDRESS, feeAmount]
          })
        }
      ] as const;
    } else {
      // It will call here if the allowance is sufficient
      calls = [
        {
          to: swapRouterAddress,
          data: encodeFunctionData({
            abi: swapRouterabi,
            functionName: "exactInputSingle",
            args: [paramsUser]
          })
        },
        {
          to: tokenIn,
          data: encodeFunctionData({
            abi: abi,
            functionName: "transfer",
            args: [FEE_RECIPIENT_ADDRESS, feeAmount]
          })
        }
      ] as const;
    }

    // Encode batch calls
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const batchData = encodeBatchCalls(calls as any);
    console.log("batchData", batchData);

    // Sign the user operation hash to generate the signature
    const userOpHashHex = ethers.utils.keccak256(batchData); // Assuming batchData is your input
    // Sign the message using `signMessage`

    const signature = await client.signMessage({
      account: client.account,
      message: { raw: userOpHashHex } // Wrap the hex string in the `raw` property
    });
    console.log("signature", signature);

    console.log("calldata", calls[0].data);
    const gasData = {
      sender: smartWalletAddress,
      nonce: "0x0",
      initCode: "0x",
      callData: batchData,
      callGasLimit: "0x0", // Set appropriate default or calculated value
      verificationGasLimit: "0x0", // Set appropriate default or calculated value
      preVerificationGas: "0x0", // Set appropriate default or calculated value
      maxFeePerGas: "0",
      maxPriorityFeePerGas: "0",
      signature: signature, 
      paymasterAndData: "0x"
    };

    //const gasEstimates = await estimateUserOperationGas(gasData, ethersProvider);
     const gasEstimates = {
       preVerificationGas: "3000000", // 1M
       verificationGasLimit: "3000000", // 1M
       callGasLimit: "3000000" // 1M
     };
    //console.log("gasEstimate", gasEstimates);
    const txParams = {
      account: client.account,
      calls,
     // ...gasEstimates
    };

    console.log("Transaction Parameters:", txParams);

    const txHash = await client.sendTransaction(txParams);
    console.log("Transaction Hash:", txHash);

    // Wait for transaction receipt
    const receipt = await ethersProvider.getTransactionReceipt(txHash);
    const normalizedReceipt: NormalizedReceipt = {
      ...receipt,
      gasUsed: receipt.gasUsed.toString(), // Convert BigNumber to string
      cumulativeGasUsed: receipt.cumulativeGasUsed.toString(),
      effectiveGasPrice: receipt.effectiveGasPrice.toString()
    };
    setReceipt(normalizedReceipt);
    setIsLoading(false);
    console.log("receipt", normalizedReceipt);
    // Declare quantityOut variable
    let quantityOut = "0";

    // Parse the receipt logs
    for (const log of receipt.logs) {
      // Check if this is a Transfer event (standard ERC20 Transfer event topic)
      if (log.topics[0] === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef") {
        const toAddress = "0x" + log.topics[2].slice(26).toLowerCase(); // Extract 'to' address

        if (toAddress === smartWalletAddress.toLowerCase()) {
          const tokenAmount = ethers.BigNumber.from(log.data);
          quantityOut = ethers.utils.formatUnits(tokenAmount, 6);
        }
      }
    }

    const amountSpent = ethers.utils.formatUnits(amountInBigNumber, 6);
    const amountFee = ethers.utils.formatUnits(feeAmount, 6);
    const transactionLog = await recordTransaction({
      user: user,
      block: receipt.blockNumber,
      transactionHash: txHash,
      action: action,
      tokenIn: tokenIn,
      tokenOut: tokenOut,
      quantityIn: parseFloat(amountSpent),
      quantityOut: parseFloat(quantityOut),
      userAddress: smartWalletAddress,
      feeCollected: amountFee,
      ticker: ticker,
      feeRecipientAddress: FEE_RECIPIENT_ADDRESS,
      smartWalletAddress: smartWalletAddress
    });
    setReceipt(transactionLog);
    return transactionLog;
  } catch (error) {
    console.error("Swap execution failed:", error);
    setIsLoading(false);
    throw error;
  }
}
