import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SharePriceContext from "sharePriceContext";
import { formatToDollar } from "common";
import LoadingPrimaryTableSkeleton from "../common/Loaders/LoadingPrimaryTableSkeleton";
function generateTableTsx(primaryCompanyData: any, navigate: any, primaryCompanyDataLoaded: boolean) {
  const filteredData = primaryCompanyData.filter((company: any) => company.userDeposit > 0);

  if (filteredData.length === 0 && primaryCompanyDataLoaded) {
    return (
      <tr>
        <td colSpan={2} className="py-2 text-center text-gray-400">
          No Allocations Yet
        </td>
      </tr>
    );
  }

  if (!primaryCompanyDataLoaded) {
    return <LoadingPrimaryTableSkeleton />;
  }

  return filteredData.map((investment: any) => {
    return (
      <tr className="border-t cursor-pointer" key={investment.title} onClick={() => navigate(investment.uri)}>
        <td className="py-2 flex flex-row items-center">
          <div className="p-1 mr-2">
            <img src={investment.image} alt={investment.title} />
          </div>
          <div className="flex flex-col">
            <div className="text-l">{investment.title}</div>
            <div className="text-xs">{investment.shareType}</div>
          </div>
        </td>
        <td className="py-2">
          <div>{formatToDollar(investment.userDeposit)}</div>
        </td>
      </tr>
    );
  });
}

const PendingTransactionsTable: FC = () => {
  const { primaryCompanyData, primaryCompanyDataLoaded } = useContext(SharePriceContext);
  const navigate = useNavigate();

  const tableTsx = generateTableTsx(primaryCompanyData, navigate, primaryCompanyDataLoaded);

  return (
    <table className="min-w-full table-auto border-collapse">
      <thead>
        <tr>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "50%" }}>
            Firm
          </th>
          <th className="text-left py-2 text-gray-400 text-xs" style={{ width: "50%" }}>
            Allocation
          </th>
        </tr>
      </thead>
      <tbody>{tableTsx}</tbody>
    </table>
  );
};
export default PendingTransactionsTable;
