import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from "react";
import { fetchAssets, getPrinxUser } from "calls";
import { usePrivy } from "@privy-io/react-auth";

interface PrinxContextType {
  //here is where we type all the state values and the functions
  assetData: [] | null;
  prinxUser: { attestation: string; _id: string } | null;
  setPrinxUser: Dispatch<SetStateAction<{ attestation: string } | null>>;
}

const PrinxContext = createContext<PrinxContextType>({
  assetData: [],
  prinxUser: null,
  setPrinxUser: () => null // simplified placeholder
});

const getUserEmail = (user: any) => {
  if (!user) return;
  if (user.email) return user.email.address;
  if (user.google) return user.google.email;
};

export const PrinxContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [assets, setAssets] = useState<any>(null);
  const { user, linkPhone } = usePrivy();
  const [prinxUser, setPrinxUser] = useState<any>(null);

  useEffect(() => {
    const getAssets = async () => {
      const assets = await fetchAssets();
      console.log("Assets?", assets);
      setAssets(assets);
    };
    if (!assets) getAssets();
  }, [assets]);

  useEffect(() => {
    async function fetchUser() {
      const email = getUserEmail(user);
      try {
        const userData = await getPrinxUser(email);
        setPrinxUser(userData); // Store user data in state
        console.log("Got Prinx User Data in prinxDataContext", userData);
      } catch (error) {
        console.error("Failed to fetch from prinxDataContext:", error);
      }
    }

    fetchUser();
  }, [user]);

  const value = {
    assetData: assets,
    prinxUser: prinxUser,
    setPrinxUser: setPrinxUser
  };

  return <PrinxContext.Provider value={value}>{children}</PrinxContext.Provider>;
};

export default PrinxContext;
