import texture from "../../assets/images/Texture.png";
import TickerImage from "../../assets/images/ticker-img.png";
import { useWindowSize } from "../../hooks";


const HowItWorks: React.FC = () => {
  const { isMobile } = useWindowSize();

  return (
    <section className="bg-[#121b33] text-white pt-24 pb-16 px-6 lg:px-20">
      {/* Section Header */}
      <div className="flex justify-start md:justify-center items-center mb-4">
        <div
          style={{
            background: "linear-gradient(90deg, #9386F4, #436FE6)",
            padding: "1px", // Border thickness
            borderRadius: "20px",
            display: "inline-block",
            margin: isMobile ? "0" : "0 auto"
          }}
        >
          <div
            style={{
              background: "#131C34", // Inner content background
              borderRadius: "20px", // Matches inner content
              padding: "6px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "18px"
            }}
          >
            HOW IT WORKS
          </div>
        </div>
      </div>
      <div className="text-left md:text-center my-6">
        <h2 className="text-4xl sm:text-6xl mb-6 tracking-widest">
          We make private market investing
          <br />
          <span className="bg-gradient-to-r from-[#D7FC70] via-[#83D2F7] to-[#436FE7] to-blue-500 bg-clip-text text-transparent italic">
            easy for everyone
          </span>
        </h2>
        <p className="text-white text-lg max-w-3xl mx-auto pb-6">
          Goodbye to traditional high-entry costs! At Prinx we believe private equity should be for everyone, not just
          the ultra-wealthy.
        </p>
      </div>

      {/* Content */}
      <div className="flex flex-col lg:flex-row gap-12 items-start">
        {/* Right Content */}
        <div className="flex justify-start items-start">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 sm:w-3/4 md:w-full lg:w-full">
            {/* Step 1 */}
            <div className="text-left flex flex-row md:flex-col items-start">
              <div className="w-10 h-10 flex-none md:flex-initial border text-white rounded-full flex items-center justify-center font-bold text-lg">
                1
              </div>
              <div className="ml-6 md:ml-0">
                <h3 className="text-2xl font-semibold mt-0 md:mt-4">Create a free account</h3>
                <p className="text-lg text-white mt-0 md:mt-2">
                  Join the platform with just your email or phone number, then secure your access by verifying your
                  identity and residency. It is quick, easy, and ensures compliance with regulations.
                </p>
              </div>
            </div>
            {/* Step 2 */}
            <div className="text-left flex flex-row md:flex-col items-start">
              <div className="w-10 h-10 flex-none md:flex-initial border text-white rounded-full flex items-center justify-center font-bold text-lg">
                2
              </div>
              <div className="ml-6 md:ml-0">
                <h3 className="text-2xl font-semibold mt-0 md:mt-4">Deposit funds</h3>
                <p className="text-white mt-0 md:mt-2">
                  We have integrated over 100+ local payment providers to make it simple for you to deposit and withdraw
                  funds.
                </p>
              </div>
            </div>
            {/* Step 3 */}
            <div className="text-left flex flex-row md:flex-col items-start">
              <div className="w-10 h-10 flex-none md:flex-initial border text-white rounded-full flex items-center justify-center font-bold text-lg">
                3
              </div>
              <div className="ml-6 md:ml-0">
                <h3 className="text-2xl font-semibold mt-0 md:mt-4">Invest in private assets</h3>
                <p className="text-white mt-0 md:mt-2">
                  For as little as $1, start investing in a range of high profile private companies and watch your
                  investments grow over time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HowItWorks;
