import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import KYCQuestions from "../../constants/questions_and_answers";
import SearchableCountrySelector from "../CountrySelect";
import { writeUserAttestation } from "calls";
import { usePrivy } from "@privy-io/react-auth";
import spinner from "../../assets/spinner2.gif";
import PrinxContext from "prinxDataContext";

interface QuestionType {
  question: string;
  warn: string;
  acceptMulti: boolean;
  fullWidthAnswer: boolean;
  requiredAnswers: number[];
  disallowed: number[];
  answers: string[];
}

const customStyles: ReactModal.Styles = {
  content: {
    zIndex: 99,
    // margin: "0 auto",
    transition: "transform 0.25s ease, opacity 0.25s ease",
    opacity: 1,
    position: "relative",
    borderRadius: "1rem",
    maxWidth: window.innerWidth > 768 ? "75%" : "90%",
    width: "90%",
    left: "50%",
    transform: "translateX(-50%)",
    inset: "0px",

    maxHeight: "80vh", // ✅ Limits height to 80% of viewport
    overflowY: "auto" // ✅ Enables scrolling when content overflows
  },
  overlay: {
    zIndex: 9,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 0.25s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

const KYCModal = ({
  onClose,
  isAnimating,
  setParentPrinxUser
}: {
  isAnimating: boolean;
  onClose: any;
  setParentPrinxUser: any;
}) => {
  const [styles, setStyles] = useState(customStyles);
  const [answers, setAnswers] = useState<Record<number, number | number[]> & { v?: number }>({ v: 0 });
  const [step, setStep] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [formSubmitted, setFormSubitted] = useState(false);
  const [country, setCountry] = useState("");

  const [doneWithQuestions, setDoneWithQuestions] = useState(false);
  const [failure, setFailure] = useState(false);
  const { prinxUser, setPrinxUser } = useContext(PrinxContext);

  const { user } = usePrivy();
  const [loading, setLoading] = useState(false);
  function validateAge() {
    if (Number(age) < 18) return true;
    return false;
  }

  useEffect(() => {
    if (isAnimating) {
      // ✅ Prevent background scrolling when modal opens
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";

      // ✅ Update modal styles for animation
      setStyles((prevStyles: any) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translateY(0)", // Animate into view
          opacity: 1 // Make visible
        },
        overlay: {
          ...prevStyles.overlay,
          opacity: 1 // Fade in overlay
        }
      }));
    }

    return () => {
      // ✅ Only restore scrolling if NO OTHER modals are open
      setTimeout(() => {
        if (!isAnimating) {
          document.body.style.overflow = "auto";
          document.documentElement.style.overflow = "auto";
        }
      }, 50); // Small delay ensures modal is actually closed
    };
  }, [isAnimating]);
  //   useEffect(() => {
  //     console.log("User Updated in KYC Modal UseEffect", user);
  //   }, [prinxUser]);

  function incrementStep(n: number) {
    if (n == -1) setDoneWithQuestions(false);
    setStep(step + n);
  }

  function generateKYCQuestionJSX(step: number) {
    const quest = step - 3; // this is how many steps there are before questions start
    const question = KYCQuestions[quest] as QuestionType;
    if (!question) {
      // Set state only if not already done
      if (!doneWithQuestions) {
        setDoneWithQuestions(true);
      }
      return null; // Stop rendering
    }
    const handleAnswerSelect = (index: number) => {
      setAnswers((prev) => {
        const currentAnswer = prev[quest];
        if (question.acceptMulti) {
          // If multi-select, ensure currentAnswer is an array
          const selectedAnswers = Array.isArray(currentAnswer) ? currentAnswer : [];
          const newAnswers = selectedAnswers.includes(index)
            ? selectedAnswers.filter((i) => i !== index) // Remove if already selected
            : [...selectedAnswers, index]; // Add if not selected
          return { ...prev, [quest]: newAnswers };
        } else {
          // For single-select, set the index directly
          return { ...prev, [quest]: index };
        }
      });
    };

    return (
      <div>
        <div className="mb-4 font-bold ">
          ({step - 2}/{KYCQuestions.length}) {question.question}
        </div>
        <div className="space-y-2">
          {question.answers.map((answer, index) => (
            <button
              key={index}
              className={`p-2 border mr-2 rounded ${
                question.acceptMulti && Array.isArray(answers[quest]) && (answers[quest] as number[]).includes(index) // Explicit cast
                  ? "bg-blue-500 text-white"
                  : answers[quest] === index
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
              } ${question.fullWidthAnswer ? "w-full" : ""}`}
              onClick={() => handleAnswerSelect(index)}
            >
              {answer}
            </button>
          ))}
        </div>
        <div className="mt-auto">
          <>
            <button className="mt-2 mr-4 bg-gray-200 p-4 flex-end" onClick={() => incrementStep(-1)}>
              Back
            </button>
            <button
              className="mt-4 bg-gray-200 p-4"
              onClick={() => {
                // Validate required answers
                if (
                  // Check if required answers are missing
                  (question.requiredAnswers.length > 0 &&
                    !question.requiredAnswers.every((req) => {
                      const selected = Array.isArray(answers[quest])
                        ? (answers[quest] as number[]) // ✅ If array, use as-is
                        : [answers[quest] as number]; // ✅ If single number, convert to array
                      return selected.includes(req);
                    })) ||
                  // Check if any selected answer is in the disallowed array
                  (question.disallowed.length > 0 &&
                    (Array.isArray(answers[quest])
                      ? (answers[quest] as number[]).some((answer) => question.disallowed.includes(answer)) // ✅ If array, use `.some()`
                      : question.disallowed.includes(answers[quest] as number))) // ✅ If single number, check directly
                ) {
                  setFailure(true);
                  return;
                }
                incrementStep(1);
                setFailure(false);
              }}
            >
              Next
            </button>
          </>
        </div>
        {failure ? <div className="text-red-500"> {question.warn} </div> : null}
      </div>
    );
  }

  async function handleSubmitAttestation() {
    setLoading(true);
    const prinxUser = await writeUserAttestation(user, answers, firstName + " " + lastName, country, age, gender);
    if (prinxUser) setPrinxUser(prinxUser);
    console.log("Set Prinx User In Submit", prinxUser);
    if (prinxUser) setParentPrinxUser(prinxUser);
    setLoading(false);
    setFormSubitted(true);
  }
  return (
    <ReactModal
      isOpen={true}
      style={styles}
      onAfterClose={() => {
        console.log("Modal closed! Restoring scrolling...");
        document.body.style.overflow = "";
        document.documentElement.style.overflow = "";
      }}
    >
      <div className="text-gray-800 max-w-4xl mx-auto flex flex-col ">
        {/* <div onClick={() => onClose()} className="absolute pointer-cursor cursor-pointer">
          X
        </div> */}
        <h1 className="text-3xl font-bold text-center mb-8">Onboarding Process </h1>
        {doneWithQuestions ? (
          !formSubmitted ? (
            <>
              <div> Questionaire is complete. Please submit your answers for approval </div>
              <button disabled={loading} className="bg-gray-200 p-4" onClick={() => handleSubmitAttestation()}>
                Submit Attestation
              </button>
              <button className="bg-gray-200 p-4 mt-4" onClick={() => incrementStep(-1)}>
                Go Back & Review
              </button>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <img width="50" src={spinner} alt="spinner" />
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="mb-8 text-center text-2xl font-bold text-green-500">
                {" "}
                Success! You now have been given approval to invest with Prinx
              </div>
              <button className="bg-gray-200 p-4" onClick={() => onClose()}>
                Close
              </button>
            </>
          )
        ) : step == 0 ? (
          <>
            <p className="text-sm text-gray-500 mb-2 text-center">
              Welcome to Prinx! We need to get to know you a little bit to learn about what you are looking for.
            </p>
            <p className="text-sm text-gray-500 mb-2 text-center">
              We need to review your investor history. Since Prinx is only available to self-certified, sophisticated
              investors, regulations require us to understand your investor profile. This questionnaire will ask you
              about your investing history. We rely on your answers to understand if Prinx is right for you. Please know
              that if we are unable to verify your sophistication from the questionnaire we may need to request further
              information. It should take no longer than five minutes but may vary slightly based on your location.
            </p>
            <footer className="mt-4 text-center text-sm text-gray-500">
              If you have any questions, please contact us at <strong>info@prinx.io</strong>.
            </footer>
            <button className="mt-4 bg-gray-200 p-4" onClick={() => incrementStep(1)}>
              Start
            </button>
          </>
        ) : null}
        {step == 1 ? (
          <>
            <div className="flex flex-row gap-4">
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="border rounded p-2 w-1/2"
                placeholder="Enter your first name"
              />

              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="border rounded p-2 w-1/2"
                placeholder="Enter your last name"
              />
            </div>

            <div className="mt-4">
              <input
                type="text"
                id="age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                className="border rounded p-2 w-1/2"
                placeholder="Enter your age"
              />
            </div>
            <div className="flex flex-col gap-2 my-4">
              <label className="font-semibold">Select Gender:</label>
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  value="male"
                  checked={gender === "male"}
                  onChange={(e) => setGender(e.target.value)}
                />
                Male
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  value="female"
                  checked={gender === "female"}
                  onChange={(e) => setGender(e.target.value)}
                />
                Female
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  value="other"
                  checked={gender === "other"}
                  onChange={(e) => setGender(e.target.value)}
                />
                Other
              </label>
            </div>
            <button disabled={validateAge()} className="bg-gray-200 p-4 flex-end" onClick={() => incrementStep(1)}>
              Next
            </button>
            <button className="mt-2 bg-gray-200 p-4 flex-end" onClick={() => incrementStep(-1)}>
              Back
            </button>
            {age && Number(age) < 18 ? "You Must Be 18 or Older To Sign Up" : null}
          </>
        ) : null}
        {step == 2 ? (
          <>
            <div>
              <h4> Country of Origin </h4>
              <SearchableCountrySelector country={country} setCountry={setCountry} />
            </div>
            <button disabled={!country} className="bg-gray-200 p-4 mt-4 flex-end" onClick={() => incrementStep(1)}>
              Next
            </button>
            <button className="mt-2 bg-gray-200 p-4 flex-end" onClick={() => incrementStep(-1)}>
              Back
            </button>
          </>
        ) : null}
        {step >= 3 && !doneWithQuestions ? generateKYCQuestionJSX(step) : null}
      </div>
    </ReactModal>
  );
};

export default KYCModal;
