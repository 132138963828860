import React from "react";
import { useWindowSize } from "../../hooks"; // adjust the import path as needed

const BackgroundShadow: React.FC = () => {
  const { isMobile } = useWindowSize();
  
  // Define different sizes based on the viewport.
  // For mobile, the sizes are smaller.
  const circle1Size = isMobile ? "55rem" : "70rem";
  const circle2Size = isMobile ? "65rem" : "90rem";

  return (
    <div className="absolute inset-0 pointer-events-none z-0">
      {/* First Shadow Circle (Top Left Corner) */}
      <div
        className="absolute rounded-full"
        style={{
          width: circle1Size,
          height: circle1Size,
          top: "0",
          left: "0",
          transform: "translate(-60%, -50%)",
          background: "radial-gradient(circle, #101F5A 0%, transparent 70%)",
          filter: "brightness(1.25)"
        }}
      ></div>
      {/* Second Shadow Circle (Bottom Right Corner) */}
      <div
        className="absolute rounded-full"
        style={{
          width: circle2Size,
          height: circle2Size,
          bottom: "0",
          right: "0",
          transform: "translate(60%, 20%)",
          background: "radial-gradient(circle, #101F5A 0%, transparent 70%)",
          filter: "brightness(1.25)"
        }}
      ></div>
    </div>
  );
};

export default BackgroundShadow;