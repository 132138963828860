import React, { FC, useContext, useEffect, useState } from "react";

import { InsufficientFunds, OverMaxAllocation } from "../Trade/ErrorMessages";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import { sendDeposit } from "../../components/Home/transactions";
import spinner from "../../assets/images/loading-gif.gif";
import { PrimaryInvestmentType } from "types";
import SharePriceContext from "sharePriceContext";
import { PrimaryInvestments } from "constants/constants";

interface DepositWidgetProps {
  smartWalletAddress: string;
  ethersProvider: any;
  usdcBalance: any;
  provider: any;
  setUsdcBalance: any;
  company: PrimaryInvestmentType | null;
}

const DepositWidget: FC<DepositWidgetProps> = ({
  smartWalletAddress,
  provider,
  ethersProvider,
  usdcBalance,
  setUsdcBalance,
  company
}) => {
  const { primaryCompanyData } = useContext(SharePriceContext);
  const [loading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { client } = useSmartWallets();
  const [selectedCompany, setSelectedCompany] = useState<PrimaryInvestmentType>(
    (company as PrimaryInvestmentType) || PrimaryInvestments[0]
  );

  const idx = primaryCompanyData.findIndex((co) => co.title == selectedCompany.title);

  const isButtonDisabled =
    Number(inputValue) > Number(usdcBalance) || Number(inputValue) > Number(primaryCompanyData[idx].goal);

  async function send(depositAmount: string): Promise<void> {
    setIsLoading(true);
    try {
      if (!client || !ethersProvider || !company?.wallet_address) return;
      await sendDeposit({
        client,
        ethersProvider,
        depositAmount,
        depositAddress: company.wallet_address,
        setIsLoading
      });
      //   // Optionally refresh the allocations after successful deposit
      //   await fetchAllocations(ethersProvider);
    } catch (error) {
      console.error("Error in send function:", error);
    }
  }

  return (
    <div className="col-span-1 bg-white p-4 relative" style={{ borderRadius: '8px' }}>
      {loading ? (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <img src={spinner} alt="Loading..." className="w-12 h-12" />
        </div>
      ) : null}
      <div style={{ backgroundColor: "#ECF1FC", borderRadius: "8px" }} className="p-4">
        <span className="text-sm px-2 text-black font-bold mr-4">ALLOCATE</span>
          <div className="flex flex-row">
            <div className="h-3 w-full" />
          </div>
        <div className="flex items-center gap-2">
          <img src={company?.image} alt={company?.title} className="w-10 h-10 rounded-full" />
          <span>{company?.title}</span>
        </div>
        <div className="mt-8 text-base pl-1 text-black font-bold mb-1">Deposit Amount</div>
        <div className="flex flex-row">
          <div className="relative w-1/2">
            <span className="absolute left-2 top-1/2 transform -translate-y-1/2">$</span>
            <input
              type="number"
              min="0.01"
              step="0.01"
              style={{ height: 40, paddingLeft: "20px", borderRadius: "8px" }}
              className="w-full"
              value={inputValue}
              onChange={(e) => {
                const value = e.target.value;
                // Allow empty input, decimal point, and valid numbers
                if (value === "" || value === "." || value === "0." || /^\d*\.?\d{0,2}$/.test(value)) {
                  setInputValue(value);
                }
              }}
              onBlur={() => {
                // On blur, format the value appropriately
                if (inputValue === "" || inputValue === "." || inputValue === "0.") {
                  setInputValue("");
                } else if (parseFloat(inputValue) < 0.01) {
                  setInputValue("0");
                } else {
                  // Format to 2 decimal places
                  setInputValue(parseFloat(inputValue).toFixed(2));
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between mt-6">
        <div className="">Estimated Share Price</div>
        <div className="">~${company?.sharePrice}</div>
      </div>
      <div className="h-px bg-gray-300 my-4" />
      <div className="flex flex-row justify-between mt-2">
        <div className="font-bold">Estimated Total Shares</div>
        <div className="font-bold">
          {company && inputValue ? `~${(parseFloat(inputValue) / Number(company.sharePrice)).toFixed(2)}` : null}
        </div>
      </div>
      <div className="h-4" />
      {primaryCompanyData[idx]?.sharePrice < 0 ? (
        <button disabled={true} style={{ backgroundColor: "#D0DBF9" }} className="w-full p-2 text-white">
          Sold Out
        </button>
      ) : (
        <button
          disabled={isButtonDisabled}
          onClick={() => send(inputValue)}
          style={{ backgroundColor: isButtonDisabled ? "#D0DBF9" : "#436FE6" }}
          className="w-full p-2 mt-4 text-white"
        >
          Deposit
        </button>
      )}
      {Number(inputValue) > Number(usdcBalance) && <InsufficientFunds usdcBalance={usdcBalance} />}
      {Number(inputValue) > Number(primaryCompanyData[idx].goal) && (
        <OverMaxAllocation
          goal={parseFloat(primaryCompanyData[idx].goal.toString()).toFixed(2)}
          amountIn={inputValue}
        />
      )}
      <div className="text-center pt-2">My Cash Balance: ${(Math.floor(Number(usdcBalance) * 100) / 100).toFixed(2)}</div>
    </div>
  );
};
export default DepositWidget;
