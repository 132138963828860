import { usePrivy, useWallets } from "@privy-io/react-auth";
import { CompanyDataType } from "constants/constants";
import { USDC_ADDRESS } from "contracts";
import React, { createContext, useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import USDC_ABI from "./contracts/usdc.json";
import PrinxContext from "prinxDataContext";

interface BlockChainContextType {
  //here is where we type all the state values and the functions
  provider: ethers.providers.ExternalProvider | null;
  ethersProvider: ethers.providers.Web3Provider | null;
  usdcBalance: string;
  setUsdcBalance: React.Dispatch<React.SetStateAction<string>>;
  usdcBalanceLoaded: boolean;
  setUsdcBalanceLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  smartWalletAddress: string;
  companyBalances: { [key: string]: string };
  companyBalancesLoaded: boolean;
  setCompanyBalances: React.Dispatch<React.SetStateAction<any>>;
}

const BlockChainContext = createContext<BlockChainContextType>({
  provider: null,
  ethersProvider: null,
  usdcBalance: "",
  setUsdcBalance: () => {},
  usdcBalanceLoaded: false,
  setUsdcBalanceLoaded: () => {},
  smartWalletAddress: "",
  companyBalances: {},
  companyBalancesLoaded: false,
  setCompanyBalances: () => {}
});

export const BlockChainContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { wallets } = useWallets();
  const { user } = usePrivy();
  const { assetData } = useContext(PrinxContext);
  const [provider, setProvider] = useState<ethers.providers.ExternalProvider | null>(null);
  const [ethersProvider, setEthersProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [smartWalletAddress, setSmartWalletAddress] = useState<string>("");
  const [usdcBalance, setUsdcBalance] = useState<string>("0");
  const [usdcBalanceLoaded, setUsdcBalanceLoaded] = useState(false);
  const [companyBalances, setCompanyBalances] = useState<{ [key: string]: string }>({});
  const [companyBalancesLoaded, setCompanyBalancesLoaded] = useState(false);

  useEffect(() => {
    if (user?.linkedAccounts && !smartWalletAddress) {
      const smartWallet = user.linkedAccounts.find((account) => account.type === "smart_wallet");
      if (smartWallet && "address" in smartWallet) {
        console.log("Setting Smart Wallet");
        setSmartWalletAddress(smartWallet.address);
      }
    }
  }, [user]);
  useEffect(() => {
    const getEthersProvider = async () => {
      const wallet = wallets[0];
      if (wallet && smartWalletAddress) {
        console.log("Setting Provider, Ethers Provider, Balance, Company Balances");
        const ethereumProvider = await wallet.getEthereumProvider();
        setProvider(ethereumProvider);
        const ethersProvider = new ethers.providers.Web3Provider(ethereumProvider);
        setEthersProvider(ethersProvider);
      }
    };
    getEthersProvider();
  }, [wallets, smartWalletAddress]);
  useEffect(() => {
    const getProvider = async () => {
      const wallet = wallets[0];
      if (wallet && smartWalletAddress && assetData) {
        if (!ethersProvider) return;
        //get USDC
        const usdcContract = new ethers.Contract(USDC_ADDRESS, USDC_ABI, ethersProvider);
        const usdcBalanceRaw = await usdcContract.balanceOf(smartWalletAddress);
        setUsdcBalance(ethers.utils.formatUnits(usdcBalanceRaw, 6));
        setUsdcBalanceLoaded(true);
        // set balances of tokens
        const balances: { [key: string]: string } = {};
        for (const company of assetData as CompanyDataType[]) {
          if (company.abi && company.address) {
            const companyContract = new ethers.Contract(
              company.address,
              company.abi as ethers.ContractInterface,
              ethersProvider
            );
            console.log("GETTING BALANCE OF ", company.title);
            const companyBalanceRaw = await companyContract.balanceOf(smartWalletAddress);
            balances[company.title] = ethers.utils.formatUnits(companyBalanceRaw, 6);
          }
        }
        setCompanyBalances(balances);
        setCompanyBalancesLoaded(true);
      }
    };
    getProvider();
  }, [ethersProvider, assetData]);

  const value = {
    provider,
    ethersProvider,
    usdcBalance,
    setUsdcBalance,
    usdcBalanceLoaded,
    setUsdcBalanceLoaded,
    companyBalances,
    companyBalancesLoaded,
    setCompanyBalances,
    smartWalletAddress
  };

  return <BlockChainContext.Provider value={value}>{children}</BlockChainContext.Provider>;
};

export default BlockChainContext;
