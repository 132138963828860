import { Buffer } from "buffer";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Roll_A_B } from "experiments/layout-experiment";
import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import Profile from "./components/Profile/Profile";
import Trade from "./components/Trade/Trade";
import Company from "./components/Company/Company";
import NotHereYet from "./components/NotHereYet/NotHereYet";
import Waitlist from "./components/Waitlist/Waitlist";
import Settings from "./components/Settings/Settings";
import LandingPage from "components/LandingPage/LandingPage";
import SharePriceContext from "sharePriceContext";
import Admin from "components/Admin/Admin";
import AdminUser from "components/Admin/AdminUser";
import { CompanyDataType, PrimaryInvestments } from "./constants/constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as Sentry from "@sentry/react";
import { MoonPayProvider } from "@moonpay/moonpay-react";
import mixpanel from "mixpanel-browser";
import "styles/App.css";
import { usePrivy } from "@privy-io/react-auth";
import { useContext, useEffect, useState } from "react";
import OfframpConfirmation from "./components/OfframpConfirmation";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";

Sentry.init({
  dsn: "https://f38b14ea50803e13b00edaf1d1e535f0@o4508413424893952.ingest.us.sentry.io/4508414122655744",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

window.addEventListener("error", (event) => {
  console.error("Uncaught Error:", event.error);
  throw new Error(event.error);
});

window.addEventListener("unhandledrejection", (event) => {
  console.error("Unhandled Rejection:", event.reason);
  throw new Error(event.reason);
});

mixpanel.init("9954cda356a51017fd6e598959d05c65", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage"
});

function generateSyndicateRoutes() {
  return PrimaryInvestments.map((c) => {
    return <Route key={`${c.uri}`} path={`${c.uri}`} element={<Company />} />;
  });
}

function App() {
  if (!window.Buffer) window.Buffer = Buffer;
  const { t } = useTranslation(); // Hook for translations
  const { companyData: CompanyData } = useContext(SharePriceContext);

  const { user } = usePrivy();
  const [AB, setAB] = useState(() => Roll_A_B(i18next.t));
  const [companyRoutes, setCompanyRoutes] = useState();
  const { client } = useSmartWallets();

  useEffect(() => {
    if (user) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $email: user.email?.address || user.google?.email,
        smartWalletAddress: user.smartWallet?.address
        // Add anything else about the user here
      });
    }
  }, [user]);

  const syndicateRoutes = generateSyndicateRoutes();
  return (
    <MoonPayProvider
      apiKey="pk_test_rKG725r23WBJ4Pf6Dgye7Kx86ehD"
      debug
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage />} />
            <Route path="/primary" element={<Home />} />
            <Route path="/primary/:company" element={<Company />} />
            <Route path="waitlist" element={<Waitlist str={AB.str} ab={AB.ab} />} />
            <Route path="profile" element={<Profile />} />
            <Route path="not-here-yet" element={<NotHereYet />} />
            <Route path="settings" element={<Settings />} />
            <Route path="trade" element={<Trade data={CompanyData as CompanyDataType[]} />} />
            <Route path="/trade/:company" element={<Company />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/users/:_id" element={<AdminUser />} />
            {syndicateRoutes}
            <Route path="*" element={<Home />} />
            <Route
              path="/offramp-confirmation"
              element={<OfframpConfirmation client={client} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </MoonPayProvider>
  );
}

export default App;
