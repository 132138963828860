import { useNavigate } from "react-router-dom";
import GraphImage from "../../assets/images/graph.png";
import { useWindowSize } from "../../hooks";


const WhyInvest: React.FC = () => {
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  const handleJoinWaitlist = () => {
    navigate("/waitlist");
  };
  return (
    <section className="pt-0 pb-20 px-6 mt-8 text-white md:pt-16 md:px-16 md:pb-24">
      <div
        style={{
          borderColor: "#8181F0",
          boxShadow: "0 0 20px 0 rgba(129, 129, 240, 0.5)"
        }}
        className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start border rounded-xl pt-8 lg:py-12 px-12 relative"
      >
        {/* Left Column: Text Content */}
        <div className="flex flex-col justify-center">
          <h2 className="text-5xl lg:text-6xl mb-6 tracking-widest">Why invest in private equity?</h2>
          <p className="text-white text-lg mb-8">
            Companies are staying private longer and growing more of their value while they are private. If you are{" "}
            <em>just</em> a public market investor, you are missing out on the enormous value creation in private
            markets.
          </p>
          <button
            onClick={handleJoinWaitlist}
            style={{ background: "#436FE7", position: "relative", top: isMobile ? 20 : 0 }}
            className="w-fit block mx-auto sm:mx-0 text-white px-6 py-3 rounded text-lg hover:bg-blue-700 transition relative sm:-top-10"
          >
            Sign Up
          </button>
        </div>

        {/* Right Column: Chart */}
        <div className="relative lg:relative flex justify-center items-end h-auto lg:h-full">
          <div className="w-[272px] lg:w-[336px] lg:absolute -bottom-12 lg:right-0">
            <img
              className="w-full"
              style={{ clipPath: "inset(0 5px 1px 0)", position: "relative", width: 350 }}
              src={GraphImage}
              alt="Graph"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhyInvest;
