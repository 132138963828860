import React, { useState } from "react";

const Tooltip = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => setIsVisible(true);
  const handleMouseLeave = () => setIsVisible(false);

  return (
    <div style={{ position: "absolute", top: "4px", right: "-20px" }} className="absolute">
      {/* Icon Trigger */}
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="absolute top-0 right-0 cursor-pointer text-xs flex items-center justify-center w-4 h-4 rounded-full border rounded-full text-gray-700"
      >
        i
      </div>

      {/* Tooltip */}
      {isVisible && (
        <div className="absolute top-14 right-4 w-64 bg-black text-white text-sm rounded shadow-lg p-3 z-50">
          Price Per Share is the estimated average price based on the quanity entered. Buy / Sell price is based on 1 share.
        </div>
      )}
    </div>
  );
};

export default Tooltip;
