import { FC, useContext, useState } from "react";
import UserSteps from "components/UserSteps";
import BlockChainContext from "blockchainContext";
import LanguageSelect from "components/LanguageSelect/LanguageSelect";
import { useMfaEnrollment, usePrivy } from "@privy-io/react-auth";
import UnlinkPhoneModal from "components/Modals/UnlinkPhoneModal";
import SliderToggle from "./MultifactorAuthToggle";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import GradientWheel from "components/common/GradientWheel";
import LoadingSkeleton from "components/common/Loaders/LoadingSkeleton";

const Settings: FC = () => {
  const { usdcBalance } = useContext(BlockChainContext);
  const { unlinkPhone, user } = usePrivy();
  const phoneAccount = user?.linkedAccounts.find((account) => account.type === "phone");
  const phoneNum = (phoneAccount as any)?.number;
  const [isWalletAddressVisible, setWalletAddressVisible] = useState(false);

  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [qrCode, setQrCode] = useState("");

  const handleUnlinkPhone = () => {
    setIsUnlinkModalOpen(true);
  };

  const handleConfirmUnlink = async () => {
    try {
      await unlinkPhone(phoneNum); // Call unlinkPhone to perform the unlinking
      setIsUnlinkModalOpen(false); // Close modal after unlinking
    } catch (error) {
      console.error("Failed to unlink phone:", error);
    }
  };

  return (
    <div
      style={{ backgroundColor: "rgb(245, 247, 253)", minHeight: "100vh" }}
      className="bg-white p-8 flex-col h-full w-full"
    >
      <div className="text-m">Settings</div>
      <GradientWheel progress={40} /> {/* 75% progress */}
      <LoadingSkeleton />
      <UserSteps usdcBalance={usdcBalance} />
      <div className="bg-white p-2 font-bold">Linked Accounts</div>
      {phoneAccount ? (
        <div className="mb-2 p-2 bg-white">
          <h2>Phone Account</h2>
          <p>{(phoneAccount as any)?.number}</p>
          <p className="text-blue-700 cursor-pointer" onClick={handleUnlinkPhone}>
            Unlink
          </p>
        </div>
      ) : null}
      <div className="p-2 bg-white font-bold my-8 pt-2">
        <h2>Wallet Address</h2>
        <p className="bg-white font-normal py-2">
          {" "}
          If you would like to send assets to your wallet, you can use the address below.
        </p>
        {user && user.smartWallet ? (
          <>
            <div className="flex items-center space-x-2 border rounded px-3 py-2 w-2/3">
              <input
                type={isWalletAddressVisible ? "text" : "password"} // Toggle between "text" and "password"
                readOnly
                className="flex-1 bg-transparent outline-none"
                value={user.smartWallet.address}
              />
              <button onClick={() => setWalletAddressVisible(!isWalletAddressVisible)} className="focus:outline-none">
                {isWalletAddressVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </>
        ) : null}
      </div>
      <div className="p-2 bg-white font-bold my-8 pt-2">
        <h2>Multifactor Authentication</h2>

        <SliderToggle />
      </div>
      <div>
        Preferred Language:
        <div className="w-1/2">
          <LanguageSelect />
        </div>
      </div>
      <UnlinkPhoneModal
        isOpen={isUnlinkModalOpen}
        onClose={() => setIsUnlinkModalOpen(false)}
        onConfirm={handleConfirmUnlink}
      />
    </div>
  );
};

export default Settings;
