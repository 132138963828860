import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSelect: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <select
      id="language-select"
      className="block w-full px-2 py-2 text-sm text-white bg-[#436FE6] focus:outline-none focus:ring focus:ring-indigo-200 rounded-md font-bold"
      value={i18n.language}
      onChange={(e) => changeLanguage(e.target.value)}
    >
      <option value="en">English</option>
      <option value="ar">العربية</option>
    </select>
  );
};

export default LanguageSelect;
