export function generateDollarShareLabels(
  action: string,
  denomination: string,
  sharesAmount: string,
  usdcAmount: string,
  idx: number,
  companyData: any
) {
  let label = "";
  let value = "";

  if (action === "buy") {
    if (denomination === "dollars") {
      label = "Shares";
      value = `~${sharesAmount[idx] || "0"}`;
    } else {
      label = "Cost";
      value = `~$${(parseFloat(usdcAmount[idx]) || 0).toFixed(2)}`;
    }
  } else {
    // sell
    if (denomination === "dollars") {
      label = "Shares To Be Sold";
      value = `~${sharesAmount[idx] || "0"}`;
    } else {
      label = "Return";
      value = `~$${(parseFloat(usdcAmount[idx]) || 0).toFixed(2)}`;
    }
  }
  if (companyData[idx] && companyData[idx].sharePrice < 0) {
    return <div className="flex flex-row justify-between mt-4"></div>;
  }
  return (
    <div className="flex flex-row justify-between mt-4">
      <div className="font-bold">{label}</div>
      <div className="font-bold">{value}</div>
    </div>
  );
}

export function determineIfButtonIsDisabled2(
  inputValue: string,
  action: string,
  denomination: string,
  usdcBalance: string,
  companyBalances: any,
  poolUsdcBalance: string,
  poolBalance: string,
  selectedCompany: any,
  usdcAmount: any,
  idx: number,
  sharesAmount: any
  //boolean?: boolean
) {
  // if (boolean) return boolean;

  if (!inputValue || Number(inputValue) <= 0) return true;

  if (action === "buy") {
    if (denomination === "dollars") {
      if (
        Number(inputValue) > Number(usdcBalance) ||
        Number(inputValue) < 1 ||
        Number(sharesAmount[idx]) > Number(poolBalance)
      )
        return true;
    } else if (denomination === "shares") {
      if (
        Number(usdcAmount[idx]) > Number(usdcBalance) ||
        Number(usdcAmount[idx]) < 1 ||
        Number(inputValue) > Number(poolBalance)
      )
        return true;
    }
  }

  if (action === "sell") {
    if (
      (denomination === "shares" &&
        (Number(inputValue) > Number(companyBalances[selectedCompany.title]) || Number(usdcAmount[idx]) < 1)) ||
      Number(usdcAmount[idx]) > Number(poolUsdcBalance)
    )
      return true;
    if (
      (denomination === "dollars" && Number(sharesAmount[idx]) > Number(companyBalances[selectedCompany.title])) ||
      Number(inputValue) > Number(poolUsdcBalance)
    )
      return true;
  }

  return false;
}
